import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Row,
  CardFooter,
} from "reactstrap";

const Pricing = () => {
  const contentCards = [
    {
      type: "Monthly",
      price: 29.99,
    },
    {
      type: "Yearly",
      price: 300,
    },
  ];

  function Carousel() {
    return (
      <>
        <Row className="d-flex justify-content-center align-items-center">
          {contentCards?.map((item, index) => {
            return (
              <Card
                key={index}
                className="mx-2 carosel_card"
                style={{
                  width: "22rem",
                }}
              >
                <CardHeader className="d-flex justify-content-center mb-0 my_plans_title py-3 text-capitalize">
                  {item.type}
                </CardHeader>
                <CardBody className="p-0 py-3">
                  <Row className="justify-content-center align-items-center pricing-card-title">
                    <span className="my_plans_dollar">$</span>
                    <span className="my_plans_price px-2">{item.price}</span>
                    <span className="my_plans_month">/{item.type}</span>
                  </Row>
                  <CardText className="d-flex justify-content-center py-2 text-muted">
                    14 Days Free Trial
                  </CardText>
                </CardBody>
                <CardFooter className="border-0 ">
                  <Button type="button" color="danger" className="subscribe">
                    Subscribe
                  </Button>
                </CardFooter>
              </Card>
            );
          })}
        </Row>
      </>
    );
  }
  return (
    <div className="py-8 features flickty_carosel" id="pricing">
      <h1 className="mb-2 text-center">Pricing</h1>
      <Carousel className="mt-2" />
    </div>
  );
};

export default Pricing;
