import { toast } from "react-toastify";
import firebase from "../../config/firebase";

const questionLoader = (data) => async (dispatch) => {
  dispatch({
    type: "QUESTION_LOADER",
    payload: data,
  });
};
export const updateCheckoutQuestion = (question) => async (dispatch) => {
  // console.log("question", question);
  try {
    const updateBatch = firebase.firestore().batch();
    question?.forEach((item) => {
      const docRef = firebase
        .firestore()
        .collection("checkoutQuestions")
        .doc(item.id);
      updateBatch.update(docRef, {
        ...item,
      });
    });
    await updateBatch.commit();
  } catch (error) {
    toast.error(error.message);
  } finally {
  }
};
export const getCheckoutQuestion = (restaurantID) => async (dispatch) => {
  dispatch(questionLoader(true));
  firebase
    .firestore()
    .collection("checkoutQuestions")
    .where("restaurantID", "==", restaurantID)
    .orderBy("position", "asc")
    .onSnapshot((query) => {
      let temp = [];
      query.docs.forEach((doc) => {
        temp.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({ type: "GET_QUESTION", payload: temp });
      dispatch(questionLoader(false));
    });
};
export const getCheckoutResponses = (Id) => async (dispatch) => {
  firebase
    .firestore()
    .collection("checkoutResponses")
    .where("questionId", "==", Id)
    .orderBy("createdAt", "desc")
    .onSnapshot((query) => {
      let temp = [];
      query.docs.forEach((doc) => {
        temp.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      // console.log("temp==>", temp);
      dispatch({ type: "GET_RESPONSES", payload: temp });
    });
};
export const addCheckoutQuestion = (obj, onSuccess) => async (dispatch) => {
  // console.log({ obj });
  dispatch(questionLoader(true));
  firebase
    .firestore()
    .collection("checkoutQuestions")
    .add(obj)
    .then(() => {
      dispatch(questionLoader(false));
      toast.success("Question Added Successfully");
      onSuccess();
    })
    .catch((err) => {
      alert(err);
      dispatch(questionLoader(false));
    });
};

export const deleteQuestion =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(deleteLoader(true));
    await firebase
      .firestore()
      .collection("checkoutQuestions")
      .doc(id)
      .delete()
      .then(() => {
        firebase
          .firestore()
          .collection("checkoutResponses")
          .where("questionId", "==", id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.delete();
            });
            dispatch(deleteLoader(false));
            onSuccess();
          });
      });
  };
export const deleteLoader = (val) => async (dispatch) => {
  dispatch({
    type: "DELETE_LOADER",
    payload: val,
  });
};

export const editCheckoutQuestion =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(editLoader(true));
    const { id, ...restPayload } = payload;
    await firebase
      .firestore()
      .collection("checkoutQuestions")
      .doc(id)
      .update({
        ...restPayload,
      })
      .then(() => {
        dispatch(editLoader(false));
        onSuccess();
      });
  };

export const editLoader = (val) => async (dispatch) => {
  dispatch({
    type: "EDIT_LOADER",
    payload: val,
  });
};
