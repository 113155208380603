import Repository from "./repository";
const peakHours = "/stats/peak-hours";
const totalCustomers = "/stats/total-customers";
const orderFrequency = "/stats/order-frequency";
const customerDemographics = "/stats/gender-percentage";
const products = "/stats/product-sales";
const payMethod = "/stats/pay-method-percentage";
const restaurant = "/stats/restaurant-revenue-earned";
const analysis = "/stats/restaurant-order-anaylsis/";
const visits = "/stats/restaurant-visits";

export default {
  getPeakHours(payload) {
    const { type, startDate, endDate, restaurantID } = payload;
    const queryParams =
      type === "range"
        ? `?type=${type}&startDate=${startDate}&endDate=${endDate}`
        : type === "merchant"
        ? `?type=${type}&restaurantID=${restaurantID}`
        : `?type=${payload}`;
    return Repository.get(`${peakHours}${queryParams}`);
  },
  getTotalCustomers(payload) {
    const { type, startDate, endDate, restaurantID } = payload;
    const queryParams =
      type === "range"
        ? `?type=${type}&startDate=${startDate}&endDate=${endDate}`
        : type === "merchant"
        ? `?type=${type}&restaurantID=${restaurantID}`
        : `?type=${payload}`;
    return Repository.get(`${totalCustomers}${queryParams}`);
  },
  getOrderFrequency(payload) {
    const { type, startDate, endDate, restaurantID } = payload;
    const queryParams =
      type === "range"
        ? `?type=${type}&startDate=${startDate}&endDate=${endDate}`
        : type === "merchant"
        ? `?type=${type}&restaurantID=${restaurantID}`
        : `?type=${payload}`;
    return Repository.get(`${orderFrequency}${queryParams}`);
  },
  // getOrderFrequency(payload) {
  //   const { type, startDate, endDate } = payload;
  //   const queryParams =
  //     type === "range"
  //       ? `?type=${type}&startDate=${startDate}&endDate=${endDate}`
  //       : `?type=${payload}`;
  //   return Repository.get(`${orderFrequency}${queryParams}`);
  // },
  getPaymentMethodPercentage(payload) {
    const { type, startDate, endDate, restaurantID } = payload;
    const queryParams =
      type === "range"
        ? `?type=${type}&startDate=${startDate}&endDate=${endDate}`
        : type === "merchant"
        ? `?type=${type}&restaurantID=${restaurantID}`
        : `?type=${payload}`;
    return Repository.get(`${payMethod}${queryParams}`);
  },

  getRestaurantCustomerDemographics(payload) {
    return Repository.get(`${customerDemographics}?restaurant_id=${payload}`);
  },
  getProducts(payload) {
    const { type, startDate, endDate, restaurantID } = payload;
    const queryParams =
      type === "range"
        ? `?type=${type}&startDate=${startDate}&endDate=${endDate}`
        : type === "merchant"
        ? `?type=${type}&restaurantID=${restaurantID}`
        : `?type=${payload}`;
    return Repository.get(`${products}${queryParams}`);
  },
  getRevenue(payload) {
    return Repository.get(`${restaurant}?restaurantID=${payload}`);
  },
  getAnalysisByID(payload) {
    console.log(payload,"Payload");
    console.log(`${analysis}?restaurantID=${payload}`,"query");
    return Repository.get(`${analysis}?restaurantID=%20${payload}`);
  },
  getRestaurantVisits(payload) {
    return Repository.get(`${visits}?restaurantID=${payload}`);
  },
};
