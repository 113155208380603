import React from "react";
import OnlyHeader from "components/Headers/OnlyHeader";
import AccoutSettingTable from "components/AccoutSetting/AccoutSettingTable";
import { Container } from "reactstrap";
import { useSelector } from "react-redux";
import { useState } from "react";
import AddSocialProfileModal from "components/Modals/AddSocialProfileModal";
import DeleteCatalogModal from "components/Modals/DeleteCatalogModal";
import EditSocialProfileModal from "components/Modals/EditSocialProfileModal";
import { toast } from "react-toastify";

const AccountSettings = () => {
  const { user, uid } = useSelector((state) => state.auth);
  console.log(user,"user.email");
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editData, setEditData] = useState("");
  const [index, setIndex] = useState("");
  const addToggle = () => {
    if (user?.socialProfiles) {
      if (user?.socialProfiles?.length < 5) {
        setAddModal(!addModal);
      } else {
        toast.error("Maximum Limit Reached..!");
      }
    } else {
      setAddModal(!addModal);
    }
  };
  const editToggle = () => {
    setEditModal(!editModal);
  };
  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  };
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <AccoutSettingTable
          data={user?.socialProfiles ? user?.socialProfiles : []}
          addToggle={addToggle}
          editToggle={editToggle}
          deleteToggle={deleteToggle}
          setEditData={setEditData}
          setIndex={setIndex}
        />
      </Container>
      <AddSocialProfileModal addModal={addModal} addToggle={addToggle} />
      <EditSocialProfileModal
        editModal={editModal}
        editToggle={editToggle}
        editData={editData}
        index={index}
      />

      <DeleteCatalogModal
        deleteModal={deleteModal}
        deleteToggle={deleteToggle}
        editData={editData}
        id={uid}
        title={"Social Profile"}
      />
    </>
  );
};

export default AccountSettings;
