import SubscriptionTable from "components/AccoutSetting/SubscriptionTable";
import OnlyHeader from "components/Headers/OnlyHeader";
import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Col, Container, Row } from "reactstrap";

export const Subscription = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname == "/admin/subscriptions" ? (
        <>
          {" "}
          <OnlyHeader />
          <Container className="mt--7" fluid>
            <Row>
              <Col>
                <SubscriptionTable />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>
          <Row>
            <Col>
              <SubscriptionTable />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
