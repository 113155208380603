import React, { useState } from "react";
import { useEffect } from "react";
import Switch from "react-input-switch";
import { useDispatch, useSelector } from "react-redux";
import { menuActivation } from "store/actions/MenuManagmentActions";

const SwitchToggler = ({ activeValue, id, collection }) => {
  const dispatch = useDispatch();
  const { restaurantMedia } = useSelector((state) => state.restaurant);
  const [value, setValue] = useState(activeValue);

  useEffect(() => {
    const active = value == 1 ? true : false;
    dispatch(menuActivation(collection, id, active));
  }, [value]);

  return (
    <Switch
      value={value}
      onChange={setValue}
      styles={{
        trackChecked: {
          backgroundColor: `${
            collection == "parentMenus"
              ? restaurantMedia?.adminColor || "#d70365"
              : "#4e4ee9"
          } `,
        },
        buttonChecked: {
          backgroundColor: "white",
        },
      }}
      width="200px"
    />
  );
};

export default SwitchToggler;
