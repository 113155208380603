import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Button,
} from "reactstrap";
import { useHistory} from "react-router-dom";
import NavbarAccordion from "./NavbarAccordion";

function LandingNavbar() {
  const history = useHistory();
  const [subMenu, setsubMenu] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const commonlinks = [
    {
      title: "About us",
      value: "aboutus",
    },
    {
      title: "FAQS",
      value: "#",
    },
  ];

  const scrollToFeatures = () => {
    const currentUrl=window.location.href;
    const pathSegments = currentUrl.split("/");
    const pageroute= pathSegments[pathSegments.length - 1];
    console.log(pageroute,"currentURL>>>>>");
    if(pageroute!=='home'){
      history.push('/home');
      setTimeout(() => {
        const featuresSection = document.getElementById("features");
        if (featuresSection) {
          featuresSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }else{
    const featuresSection = document.getElementById("features");
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: "smooth" });
    }
  }
  };
  const scrollToPricing = () => {
    const currentUrl=window.location.href;
    const pathSegments = currentUrl.split("/");
    const pageroute= pathSegments[pathSegments.length - 1];
    console.log(pageroute,"currentURL>>>>>");
    if(pageroute!=='home'){
      history.push('/home');
      setTimeout(() => {
        const pricingSection = document.getElementById("pricing");
        if (pricingSection) {
          pricingSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
    else{
    const pricingSection = document.getElementById("pricing");
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: "smooth" });
    }
  }
  };
  return (
    <div>
      <Navbar light expand="md">
        <NavbarBrand href="#">
          {/* <img src={logo} width="100" height="70" /> */}
          <h1 className="nav_brand">NextEats</h1>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse
          isOpen={isOpen}
          navbar
          className="d-md-flex justify-content-md-between mt-md-3"
        >
          <div className="d-flex d-md-none justify-content-end mx-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="cursor-pointer"
              width={25}
              height={25}
              onClick={() => setIsOpen(false)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>

          <Nav className="me-auto d-none d-md-flex" navbar>
            <p
              className="mx-3 mb-0 position-relative nav_links"
              onMouseEnter={() => setsubMenu(1)}
              onMouseLeave={() => setsubMenu(-1)}
            >
              Why NextEats
            </p>
            <p
              className="mx-3 mb-0 nav_links cursor-pointer"
              onClick={scrollToFeatures}
            >
              Features
            </p>
            <p className="mx-3 mb-0 cursor-pointer nav_links" onClick={scrollToPricing}>Pricing</p>
            <p className="mx-3 mb-0 cursor-pointer nav_links" onClick={()=>history.push('/about')}>About Us</p>
            <p className="mx-3 mb-0 cursor-pointer nav_links" onClick={()=>history.push('/faqs')}>FAQS</p>
            <p className="mx-3 mb-0 cursor-pointer nav_links" onClick={()=>history.push('/tutorials')}>Tutorials</p>
            <p className="mx-3 mb-0 cursor-pointer nav_links" onClick={()=>history.push('/contact')}>Contact Us</p>
          </Nav>
          <Nav className="me-auto d-block d-md-none" navbar>
            <NavbarAccordion title="Why NextEats" content={commonlinks} />
            <p
              className="mb-0 nav_pricing ml-3 my-2"
              onClick={scrollToFeatures}
            >
              Features
            </p>
            <p className="nav_pricing ml-3 my-2"  onClick={scrollToPricing}>Pricing</p>
            <p className="nav_pricing ml-3 my-2"  onClick={()=>history.push('/about')}>About Us</p>
            <p className="nav_pricing ml-3 my-2" onClick={()=>history.push('/faqs')}>FAQS</p>
            <p className="mx-3 mb-0 cursor-pointer nav_links" onClick={()=>history.push('/tutorials')}>Tutorials</p>
            <p className="mx-3 mb-0 cursor-pointer nav_links" onClick={()=>history.push('/contact')}>Contact Us</p>
          </Nav>

          <div className="nav_buttons">
            <Button
              color="danger"
              className="py-3 px-3  mt-3 mt-md-0"
              onClick={() => history.push("/auth/login")}
            >
              Get Started
            </Button>
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default LandingNavbar;
