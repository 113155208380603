import React from "react";
import Flickity from "react-flickity-component";
import { Card, CardBody, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import { LuDot } from "react-icons/lu";

const Features = () => {
  const contentCards = [
    {
      title: "Menu Management:",
      desc: [
        "Easy Item Creation: Enables quick addition and editing of menu items.",
        "Item Details: Supports images, descriptions, prices, and dietary information.",
        "Seasonal/Special Menus: Ability to create limited-time menus and specials.",
      ],
      // link: "Stock &amp; Inventory",
      status: "Updated",
    },
    {
      title: "Order Management:",
      desc: [
        "Tableside Ordering: Waitstaff can take orders on handheld devices, transmitting them directly to the kitchen.",
        "Split Bills: Easily divide bills among diners and itemize charges.",
        "Customizable Order Modifiers: Allows customers to customize their orders, e.g., extra cheese or no onions.",
      ],
      // link: "Stock &amp; Inventory",
      status: "Updated",
    },
    {
      title: "Customer Relationship Management (CRM):",
      desc: [
        "Customer Profiles: Stores customer data, including contact information, preferences, and visit history.",
        "Loyalty Programs: Offers loyalty programs with rewards and discounts.",
        "Personalized Marketing: Segments customers for targeted promotions.",
      ],
      // link: "Stock &amp; Inventory",
      status: "Updated",
    },
    {
      title: "Table Reservation System:",
      desc: [
        "Online Booking: Customers can reserve tables through your website or mobile app.",
        "Offline Booking: Staff can manually record phone or in-person reservations.",
        "Real-Time Availability: Provides instant information on available tables.",
        "Confirmation Notifications: Sends automated confirmation messages to customers.",
      ],
      // link: "Stock &amp; Inventory",
      status: "Updated",
    },
  ];

  const flickityOptions = {
    initialIndex: Math.floor(contentCards.length / 2),
    wrapAround: true,
    autoPlay: 2000,
  };

  function Carousel() {
    return (
      <Flickity options={flickityOptions}>
        {contentCards?.map((item,index) => {
          return (
            <Card key={index}
              className="mx-2 carosel_card"
              style={{
                width: "22rem",
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <svg
                  role="img"
                  title="Stock Management"
                  viewBox="0 0 32 32"
                  width={25}
                  height={25}
                >
                  <path
                    fill="none"
                    stroke="#fc968b"
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    strokeMiterlimit="4"
                    strokeWidth="2.909"
                    d="M22.545 12.218L9.454 4.669m19.637 17.149V10.182a2.907 2.907 0 00-1.455-2.516L17.454 1.848c-.9-.52-2.009-.52-2.909 0L4.363 7.666a2.911 2.911 0 00-1.455 2.516v11.636a2.907 2.907 0 001.455 2.516l10.182 5.818c.9.52 2.009.52 2.909 0l10.182-5.818a2.911 2.911 0 001.455-2.516z"
                  ></path>
                  <path
                    fill="none"
                    stroke="#fc968b"
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    strokeMiterlimit="4"
                    strokeWidth="2.909"
                    d="M3.302 8.669L16 16.014l12.698-7.345M16 30.662V16"
                  ></path>
                </svg>
                {item.status && (
                  <div className="carosel_card-tag">{item.status}</div>
                )}
              </div>
              <CardBody className="p-0 py-3">
                <CardText className="carosel_card-title">{item.title}</CardText>
                {item.desc?.map((itmdes,ind) => {
                  return (
                    <CardText key={ind} className="carosel_card-desc mb-1">
                      <LuDot /> {itmdes}
                    </CardText>
                  );
                })}
              </CardBody>
              {item.link && (
                <div className="d-flex justify-content-between align-items-center cursor-pointer">
                  <Link to="/" className="carosel_card-link mb-0">
                    {item.link}
                  </Link>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#fc968b"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-right"
                    aria-hidden="true"
                  >
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                </div>
              )}
            </Card>
          );
        })}
      </Flickity>
    );
  }
  return (
    <div className="mt-4 py-5 features flickty_carosel" id="features">
      <h1 className="my-4 text-center">
        Get your <span>clients</span> back in more often, spending more &
        generating referrals!
      </h1>
      <Carousel />
    </div>
  );
};

export default Features;
