import React, { useState } from "react";
import MyStatefulEditor from "./rte_test";

const RichEditor = ({ comments, setComments }) => {
  // const [val, setVal] = useState("");
  const onChange = (value) => {
    setComments(value);
  };
  return (
    <div>
      <MyStatefulEditor markup={comments} onChange={onChange} />
    </div>
  );
};
export default RichEditor;
