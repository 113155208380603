const initState = {
	loading: false,
	tutorials: [],
};
const tutorialsReducer = (state = initState, action) => {
	switch (action.type) {
		case 'GET_TUTORIALS':
			return {
				...state,
				tutorials: action.payload,
			};
            case 'TUTORIALS_LOADER':
			return {
				...state,
				loading: action.payload,
			};

		default:
			return {
				...state,
			};
	}
};
export default tutorialsReducer;
