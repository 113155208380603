import OnlyHeader from "components/Headers/OnlyHeader";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactSwitch from "react-switch";
import {
  Row,
  Container,
  Card,
  Col,
  Input,
  Button,
  Form,
  Spinner,
  Label,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,InputGroup
} from "reactstrap";
import { Link } from "react-router-dom";

import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { addForwardFees } from "store/actions/settingAction";
import { updateForwardFees } from "store/actions/settingAction";
import { addPaymentConnections } from "store/actions/settingAction";
import { addPaymentMethodDetails } from "store/actions/settingAction";
import PaymentConnectionTable from "components/PaymentConnection/PaymentConnectionTable";
import { getForwardFees } from "store/actions/settingAction";
import { getRestaurantCurrency } from "store/actions/orderActions";
function PaymentConnection() {
  const [show, setshow] = useState(false);
  const handleClick = () => setshow(!show);
  const dispatch = useDispatch();
  const { user, uid } = useSelector((state) => state.auth);
  const { restaurantMedia } = useSelector((state) => state.restaurant);
  const {
    restaurantCurrency,
  } = useSelector((state) => state.requests);
  useEffect(() => {
    dispatch(getRestaurantCurrency(user?.restaurantID));
  }, [user?.restaurantID]);
  console.log(restaurantCurrency,"restaurantCurrency");
  const { loading } = useSelector((state) => state.catalog);
  const { forwardFee, paymentConnectionLoading, currencyLoading } = useSelector(
    (state) => state.setting
  );

  const [checkedCards, setCheckedCards] = useState([]);
  const [paypalFormData, setPaypalFormData] = useState({
    paypalClientKey: "",
    paypalSecretKey: "",
  });
  const [stripeFormData, setStripeFormData] = useState({
    stripeClientKey: "",
    stripeSecretKey: "",
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const [cardId, setCardId] = useState("");
  const [methodName, setMethodName] = useState("paypal");
  const [isChecked, setIsChecked] = useState("");

  const [value, setValue] = useState("");
  const [percentageValue, setPercentageValue] = useState("");
  const [isExist, setIsExist] = useState(null);
  const [index, setIndex] = useState(null);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const [currentActiveTab, setCurrentActiveTab] = useState("1");

  // Toggle active state for Tab
  const toggleTab = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  const paymentList = [
    { value: "paypal", label: "Paypal" },
    { value: "stripe", label: "Stripe" },
  ];
  const filteredPaymentList = paymentList.filter(
    (payment) => !forwardFee?.some((fee) => fee.provider === payment.value)
  );
  const data = [
    {
      id: "1",
      name: "paypal",
      data: <i className="fab fa-cc-paypal fa-5x pointer"></i>,
    },
    {
      id: "2",
      name: "stripe",
      data: <i className="fab fa-cc-stripe fa-5x pointer"></i>,
    },
  
  ];
  const handleCheckboxChange = (event) => {
    const cardId = event.target.value;

    const methodName = event.target.name;

    const isChecked = event.target.checked;

    if (checkedCards.includes(cardId)) {
      if (methodName == "paypal" && user?.paymentConnections?.paypal) {
        dispatch(addPaymentConnections(uid, methodName, () => {}));
      }
      if (methodName == "stripe" && user?.paymentConnections?.stripe) {
        dispatch(addPaymentConnections(uid, methodName, () => {}));
      }

      setCheckedCards((prevCheckedCards) =>
        prevCheckedCards.filter((id) => id !== cardId)
      );
    } else {
      setCheckedCards((prevCheckedCards) => [...prevCheckedCards, cardId]);
    }

    setIsChecked(event.target.checked);
    setMethodName(event.target.name);
    setCardId(event.target.value);
  };

  const history = useHistory();
  const handleForwardFee = (evt) => {
    evt.preventDefault();

    let obj = {
      fixedAmount: "",
      percentageAmount: "",
      provider: paymentMethod,
      type: paymentType,
    };

    if (paymentType === "both") {
      obj.fixedAmount = value;
      obj.percentageAmount = percentageValue;
    } else if (paymentType === "percentage") {
      obj.percentageAmount = value;
    } else {
      obj.fixedAmount = value;
    }

    if (isExist) {
      dispatch(
        updateForwardFees(uid, index, obj, () => {
          setPaymentMethod("");
          setPaymentType("");
          setValue("");
          setPercentageValue("");
          setIsExist(null);
          setIndex(null);
        })
      );
    } else {
      dispatch(
        addForwardFees(uid, obj, () => {
          setPaymentMethod("");
          setPaymentType("");
          setValue("");
          setPercentageValue("");
        })
      );
    }
  };

  useEffect(() => {
    if (user?.forwardFees) {
      let obj = user?.forwardFees?.find(
        (ele) => ele?.provider == paymentMethod
      );
      if (obj) {
        setIsExist(obj);
        setPaymentType(obj?.type);
        setValue(obj?.amount);
      }
      let indexVal = user?.forwardFees?.findIndex(
        (ele) => ele?.provider === paymentMethod
      );
      if (indexVal == "-1") {
        setIndex(null);
      } else {
        setIndex(indexVal);
      }
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (user?.paymentConnections) {
      if (user?.paymentConnections?.paypal && !checkedCards.includes("1")) {
        setCheckedCards((prevCheckedCards) => [...prevCheckedCards, "1"]);
      }
      if (user?.paymentConnections?.stripe && !checkedCards.includes("2")) {
        setCheckedCards((prevCheckedCards) => [...prevCheckedCards, "2"]);
      }
      if (user?.paypalClientKey) {
        setPaypalFormData((prevState) => ({
          ...prevState,
          paypalClientKey: user?.paypalClientKey,
        }));
      }
      if (user?.paypalSecretKey) {
        setPaypalFormData((prevState) => ({
          ...prevState,
          paypalSecretKey: user?.paypalSecretKey,
        }));
      }
      if (user?.stripeClientKey) {
        setStripeFormData((prevState) => ({
          ...prevState,
          stripeClientKey: user?.stripeClientKey,
        }));
      }
      if (user?.stripeSecretKey) {
        setStripeFormData((prevState) => ({
          ...prevState,
          stripeSecretKey: user?.stripeSecretKey,
        }));
      }
    }
  }, [user]);

  // Paypal Form Handler
  const handlePaypalFormInput = (e) => {
    const { name, value } = e.target;
    setPaypalFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [disablePaypalSubmit,setdisablePaypalSubmit]=useState(false);
  const [disableStripeSubmit,setdisableStripeSubmit]=useState(false);
  const handlePaypalFormSubmit = (e) => {
    e.preventDefault();
    dispatch(addPaymentConnections(uid, methodName, () => {}));
    dispatch(addPaymentMethodDetails(uid, paypalFormData, "paypal", () => {setdisablePaypalSubmit(true)}));
  };
  // Stripe form handler
  const handleStripeFormInput = (e) => {
    const { name, value } = e.target;
    setStripeFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleStripeFormSubmit = (e) => {
    e.preventDefault();
    dispatch(addPaymentConnections(uid, methodName, () => {}));
    dispatch(addPaymentMethodDetails(uid, stripeFormData, "stripe", () => {setdisableStripeSubmit(true)}));
  };

  useEffect(() => {
    dispatch(getForwardFees(user?.restaurantID));
  }, []);
 const handleChangeValue=(value)=>{
  if(value>=0){
    setValue(value);
  }
  else{
    setValue('')
  }
 }
 const isSubmitDisabled = () => {
  if (
    paypalFormData.paypalSecretKey === "" ||
    paypalFormData.paypalClientKey === ""
  ) {
    return true;
  } else if (paypalFormData.paypalSecretKey === user?.paypalSecretKey) {
    return true;
  } else {
    return false;
  }
};
const isSubmitDisabledStripe = () => {
  if (
    stripeFormData?.stripeClientKey === "" ||
  stripeFormData?.stripeSecretKey === ""
  ) {
    return true;
  } else if (stripeFormData.stripeSecretKey === user?.stripeSecretKey) {
    return true;
  } else {
    return false;
  }
};
  return (
    <>
      <OnlyHeader />
      <Container fluid className="mt--7">
        <div className="col">
          <Card className="shadow pb-4">
            <Row className="d-lg-flex d-sm-block justify-content-between mx-0 py-3 ">
              <Col className="col-3 d-flex align-items-center">
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => {
                    history.push("/admin/client-setting-menu");
                  }}
                >
                  <i className="fas fa-arrow-left "></i>
                </Button>

                <h4 className=" pt-2 ">Payment Connection</h4>
              </Col>
              <Col
                className={`col-9 d-flex align-items-center justify-content-${
                  checked ? "center" : "end"
                }`}
              >
                <Row className="d-flex align-items-center">
                  {checked ? (
                    <Col className="d-flex col-8">
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect1"
                        className="mx-1"
                        value={paymentMethod}
                        onChange={(e) => {
                          setPaymentMethod(e.target.value);
                        }}
                      >
                        <option value="" disabled selected>
                          Select Payment Method
                        </option>
                        {filteredPaymentList.map((payment) => (
                          <option key={payment.value} value={payment.value}>
                            {payment.label}
                          </option>
                        ))}
                      </Input>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        className="mx-1"
                        value={paymentType}
                        onChange={(e) => {
                          setPaymentType(e.target.value);
                        }}
                      >
                        <option value="" selected disabled>
                          Payment Type
                        </option>
                        <option value="percentage">Percentage</option>
                        <option value="fixed_price">Fixed Price</option>
                        <option value="both">Both</option>
                      </Input>
                      {paymentType == "both" && (
                        <Input
                          type="number"
                          min={0}
                          step="any"
                          className="mx-1"
                          value={percentageValue}
                          onChange={(e) => setPercentageValue(e.target.value)}
                          placeholder="Percentage"
                        />
                      )}
                      <Input
                        type="number"
                        value={value}
                        min={0}
                        step="any"
                        onChange={(e) => handleChangeValue(e.target.value)}
                        placeholder={`Enter Fee ${restaurantCurrency}`}
                      />
                      <Button
                        color="primary"
                        className="ml-3 btn-sm"
                        type="submit"
                        style={{
                          background: restaurantMedia?.adminColor || "#d70365",
                          color: "#ffffff",
                        }}
                        onClick={handleForwardFee}
                        disabled={
                          loading || !paymentMethod || !paymentType || !value
                        }
                      >
                        {loading ? <Spinner size="sm" /> : "Save"}
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col
                    className={`d-flex align-items-center justify-content-end ${
                      checked ? "col-4" : ""
                    }`}
                  >
                    {" "}
                    <ReactSwitch
                      onChange={handleChange}
                      checked={checked}
                      className="react-switch"
                    />
                   {!checked && <span className="ml-2 " style={{ fontSize: "12px" }}>
                      Forward Fee to Client
                    </span>}
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Table */}

            {checked && (
              <Row>
                <Col>
                  {" "}
                  <PaymentConnectionTable
                    currencyLoading={currencyLoading}
                    forwardFee={forwardFee}
                    paymentConnectionLoading={paymentConnectionLoading}
                    restaurantCurrency={restaurantCurrency}
                  />
                </Col>
              </Row>
            )}
           
            <hr></hr>
            {!checked && <div className="d-flex justify-content-center align-items-center "><small className="text-info">Please Toggle Button to Forward Fee to Client</small></div>}
            {/* Tabs  */}

            <div className="d-flex justify-content-center">
              <div
                style={{
                  display: "block",
                  width: 700,
                  padding: 30,
                }}
                className="flex justify-content-center"
              >
                {/* <h4>ReactJS Reactstrap Tab Component</h4> */}
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "1",
                        "cursor-pointer": true,
                      })}
                      onClick={() => {
                        setMethodName("paypal");
                        toggleTab("1");
                      }}
                    >
                      Paypal
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "2",
                        "cursor-pointer": true,
                      })}
                      onClick={() => {
                        setMethodName("stripe");
                        toggleTab("2");
                      }}
                    >
                      Stripe
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={currentActiveTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <Form onSubmit={handlePaypalFormSubmit}>
                          <Row>
                            <Col className=" py-3 d-flex flex-column align-items-center">
                              <i className="fab fa-cc-paypal fa-5x pointer"></i>
                            </Col>
                          </Row>
                          <h2 className="py-2">
                            Enter Paypal Payment Method Details
                          </h2>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label htmlFor="paypalClientKey">
                                  Paypal Client ID
                                </Label>
                                <Input
                                className="border border-light"
                                  id="paypalClientKey"
                                  name="paypalClientKey"
                                  placeholder="Enter Paypal Client ID"
                                  type="text"
                                  value={paypalFormData.paypalClientKey}
                                  onChange={(event) => {
                                    handlePaypalFormInput(event);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                            <FormGroup>
                            <Label htmlFor="paypalSecretKey">
                                  Paypal Client Secret
                                </Label>
                <InputGroup className="border border-light">
                  <Input
                   className="border-0"
                   id="paypalSecretKey"
                   name="paypalSecretKey"
                   placeholder="Enter Paypal Client Secret"
                   type={show ? "text" : "password"}
                   value={paypalFormData.paypalSecretKey}
                   onChange={(event) => {
                     handlePaypalFormInput(event);
                   }}
                  />
                  <Link
                    role="button"
                    className="mt-2 mr-1 px-2 text-default rounded-pill"
                    onClick={handleClick}
                  >
                    {" "}
                    {show ? (
                      <small>
                        <i className="fas fa-eye-slash text-muted"></i>
                      </small>
                    ) : (
                      <small>
                        <i className="fas fa-eye text-muted"></i>
                      </small>
                    )}
                  </Link>
                </InputGroup>
              </FormGroup>
                            </Col>
                          </Row>
                          <Button
                            type="submit"
                            style={{
                              background:
                                restaurantMedia?.adminColor || "#d70365",
                              color: "#ffffff",
                            }}
                            disabled={
                              isSubmitDisabled()
                            }
                          >
                           Submit
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">

                        <Form onSubmit={handleStripeFormSubmit}>
                          <Row>
                            <Col className=" py-3 d-flex flex-column align-items-center">
                              <i className="fab fa-cc-stripe fa-5x pointer"></i>
                            </Col>
                          </Row>
                          <h2 className="py-2">
                            Enter Stripe Payment Method Details
                          </h2>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label htmlFor="stripeClientKey">
                                  Stripe Client ID
                                </Label>
                                <Input
                                  className="border border-light"
                                  id="stripeClientKey"
                                  name="stripeClientKey"
                                  placeholder="Enter Stripe Client ID"
                                  type="text"
                                  value={stripeFormData.stripeClientKey}
                                  onChange={handleStripeFormInput}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                            <FormGroup>
                            <Label htmlFor="stripeSecretKey">
                                  Stripe Client Secret
                                </Label>
                <InputGroup className="border border-light">
                  <Input
                   className="border-0"
                   id="stripeSecretKey"
                   name="stripeSecretKey"
                   placeholder="Enter Stripe Client Secret"
                   type="password"
                   value={stripeFormData.stripeSecretKey}
                   onChange={handleStripeFormInput}
                  />
                  <Link
                    role="button"
                    className="mt-2 mr-1 px-2 text-default rounded-pill"
                    onClick={handleClick}
                  >
                    {" "}
                    {show ? (
                      <small>
                        <i className="fas fa-eye-slash text-muted"></i>
                      </small>
                    ) : (
                      <small>
                        <i className="fas fa-eye text-muted"></i>
                      </small>
                    )}
                  </Link>
                </InputGroup>
              </FormGroup>
                            </Col>
                          </Row>
                          <Button
                            type="submit"
                            style={{
                              background:
                                restaurantMedia?.adminColor || "#d70365",
                              color: "#ffffff",
                            }}
                            disabled={
                              isSubmitDisabledStripe()
                            }
                          >
                           Submit
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <hr></hr>

            {/*  */}
          </Card>
        </div>
      </Container>
    </>
  );
}

export default PaymentConnection;
