import firebase from "../../config/firebase";
export const getFaqs = () => async (dispatch) => {
    try{
      dispatch(faqsLoader(true));
  firebase
      .firestore()
      .collection("faqs")
      .onSnapshot((query) => {
        let faqs = [];
        for (let doc of query.docs) {
          faqs.push({ id: doc.id, ...doc.data() });
        }
        dispatch(faqsLoader(false));
        dispatch({
          type: "GET_FAQS",
          payload: faqs,
        });
      });
    }catch(error){
      dispatch(faqsLoader(false));
      console.log(error);
    }
};
const faqsLoader = (data) => async (dispatch) => {
    dispatch({
      type: "FAQS_LOADER",
      payload: data,
    });
  };