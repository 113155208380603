import firebase from "../../config/firebase";
import algoliasearch from "algoliasearch";
import {toast} from 'react-toastify';
import { createNullCache } from "@algolia/cache-common";
const client = algoliasearch("GPYJEAVUBP", "b1f999bc5b264defe930762391d240c6", {
  responsesCache: createNullCache(),
});

export const getAllStaff =
  (ID, search, hitsPerPage, currentPage) => async (dispatch) => {
    dispatch(staffLoader(true));
    let newHits = [];
    const index = client.initIndex("users");

    index
      .search(search, {
        filters: `isDeleted:false AND (type:kitchen-admin OR type:kitchen-cook) AND restaurantID:${ID}`,
        hitsPerPage: hitsPerPage,
        page: currentPage,
      })
      .then((response) => {
        let { hits, ...rest } = response;

        hits.forEach((hit) => {
          firebase
            .firestore()
            .collection("permissions")
            .where("userID", "==", hit.id)
            .onSnapshot((querySnapshot) => {
              let docs = {};
              querySnapshot.forEach((doc) => {
                // docs.push({ id: doc.id, ...doc.data() });
                docs = { id: doc.id, ...doc.data() };
              });
              newHits.push({ ...hit, permissions: docs });
              dispatch({
                type: "GET_ALL_STAFF",
                payload: newHits,
              });
              dispatch({ type: "STAFF_DETAILS", payload: rest });
              dispatch(staffLoader(false));
            });
        });
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  };

export const updateStaff =
  (id, obj, permissionId, permissions, onSuccess) => async (dispatch) => {
    dispatch(staffLoader(true));
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .update(obj)
      .then(async () => {
        dispatch(staffLoader(false));
        await firebase
          .firestore()
          .collection("permissions")
          .doc(permissionId)
          .set({
            userID: id,
            ...permissions,
          });
        dispatch({
          type: "STAFF_UPDATED",
          payload: {
            id,
            obj,
            // permissions,
          },
        });
        onSuccess();
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(staffLoader(false));
      });
  };

export const deleteStaff =
  (id, permissionId,onSuccess=()=>{}) => async (dispatch) => {
    console.log(id,permissionId,"id and permissionid>>>>>>");
    dispatch(staffLoader(true));
    await firebase.firestore()
      .collection("users")
      .doc(id)
      .update({
        isDeleted: true,
      })
      .then(async () => {
        await firebase
          .firestore()
          .collection("permissions")
          .doc(permissionId)
          .delete().then(()=>{
            toast.success("Staff has been deleted Successfully...");
            dispatch({
              type: "STAFF_DELETED",
              payload: id,
            });
            dispatch(staffLoader(false));
            onSuccess();
          })
          .catch((error) => {
            console.error("Error deleting documents: ", error);
            dispatch(staffLoader(false));
          });
          dispatch(staffLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(staffLoader(false));
      });
  };

const staffLoader = (data) => async (dispatch) => {
  dispatch({
    type: "STAFF_LOADER",
    payload: data,
  });
};
