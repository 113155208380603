import React from "react";
import { Container} from "reactstrap";
import LandingNavbar from "./LandingNavbar";

const LandingHeader = () => {

  return (
    <div className="bg-white header ">
      <Container fluid>
        <LandingNavbar />
      </Container>
    </div>
  );
};

export default LandingHeader;
