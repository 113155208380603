import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Input,
  Col,
  Badge,
  Button,
} from "reactstrap";
import { addDays } from "date-fns";
import PickDateRange from "views/auth/PickDateRange";
import { useEffect } from "react";
import { getRequests } from "store/actions/RequestActions";
import { updateRequest } from "store/actions/RequestActions";
function RequestTable({ data }) {
  const dispatch = useDispatch();
  const { user, userPermissions } = useSelector((state) => state.auth);
  const { restaurantRequestsRest } = useSelector((state) => state.requests);
  const filteredData=data.filter((item)=>{
    const timestamp = item?.createdAt;

    // Convert the timestamp to a JavaScript Date object
    const timestampDate = new Date(
      timestamp._seconds * 1000 +
        timestamp._nanoseconds / 1000000
    );
    const currentTime = moment();
    const minutesPassed = currentTime.diff(
      timestampDate,
      "minutes"
    );
    if(minutesPassed <= 60){
      return item;
    }
  })
  const requestPermissions = userPermissions?.requests;
  const [searchField, setSearchField] = useState("");
  const [hitsPerPage, setHitsPerPage] = useState(5);

  const [datestate, setDateState] = useState([
    {
      startDate: addDays(new Date(), -49),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const handleClick = (page) => {
    setCurrentPage(page);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = restaurantRequestsRest?.nbPages;
    if (totalPages >= 1) {
      const visiblePages = 3; // or 4, depending on your preference
      let startPage = Math.max(currentPage - 1, 0);
      let endPage = Math.min(startPage + visiblePages - 1, totalPages - 1);
      if (startPage > 0) {
        pageNumbers.push(
          <PaginationItem key="start-ellipsis" disabled>
            <PaginationLink href="">...</PaginationLink>
          </PaginationItem>
        );
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <PaginationItem key={i} active={i === currentPage}>
            <PaginationLink href="" onClick={() => handleClick(i)}>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        );
      }

      if (endPage < totalPages - 1) {
        if (endPage < totalPages - 2) {
          pageNumbers.push(
            <PaginationItem key="end-ellipsis" disabled>
              <PaginationLink href="">...</PaginationLink>
            </PaginationItem>
          );
        }

        pageNumbers.push(
          <PaginationItem key={totalPages - 1}>
            <PaginationLink href="" onClick={() => handleClick(totalPages - 1)}>
              {totalPages}
            </PaginationLink>
          </PaginationItem>
        );
      }
    }

    return pageNumbers;
  };
  const startWeek = moment().unix();
  const endWeek = moment().subtract(7, "days").unix();

  const startDate = moment(datestate[0]?.startDate).unix();
  const endDate = moment(datestate[0]?.endDate).unix();
  useEffect(() => {
    dispatch(
      getRequests(
        searchField,
        user?.restaurantID,
        pageSize,
        currentPage,
        startDate,
        endDate
      )
    );

    const interval = setInterval(() => {
      dispatch(
        getRequests(
          searchField,
          user?.restaurantID,
          pageSize,
          currentPage,
          startDate,
          endDate
        )
      );
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [searchField, currentPage, startDate, endDate, user]);

  const handleUpdateRequest = (element, status) => {
    dispatch(updateRequest(element.id, status));
  };

  return (
    <Row>
      <div className="col">
        <Card className="shadow">
          <CardHeader className="d-lg-flex justify-content-between">
            <h3>Requests</h3>
            <Row>
              <Col xs="12" sm="4">
                <Input
                  placeholder="Search here..."
                  value={searchField}
                  onChange={(e) => setSearchField(e.target.value)}
                />
              </Col>
              <Col xs="12" sm="3" className="mt-2 mt-sm-0">
                <Input
                  type="select"
                  id="exampleCustomSelect"
                  name="customSelect"
                  value={hitsPerPage}
                  onChange={(e) => {
                    setHitsPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="">Show Entries</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="10">20</option>
                  <option value="50">50</option>
                </Input>
              </Col>
              <Col xs="12" sm="5">
                <PickDateRange
                  setDateState={setDateState}
                  datestate={datestate}
                />
              </Col>
            </Row>
          </CardHeader>
          {user?.type == "kitchen-admin" ? (
            <>
              {requestPermissions?.get ? (
                <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Table No.</th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Comments</th>
                        <th scope="col">Created</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((el, id) => {
                        const timestamp = el?.createdAt;

                        // Convert the timestamp to a JavaScript Date object
                        const timestampDate = new Date(
                          timestamp._seconds * 1000 +
                            timestamp._nanoseconds / 1000000
                        );
                        const currentTime = moment();
                        const minutesPassed = currentTime.diff(
                          timestampDate,
                          "minutes"
                        );

                        let color = ""; // Initialize the color variable

                        if (minutesPassed < 5) {
                          color = "bg-green";
                        } else if (minutesPassed >= 5 && minutesPassed <= 10) {
                          color = "bg-orange";
                        } else {
                          color = "bg-red";
                        }
                        return (
                          <tr key={id} className={color}>
                            <th scope="row">{el?.tableNumber}</th>
                            <td>{el?.customerName}</td>
                            <td>{minutesPassed} minutes ago</td>
                            <td> {el?.comments}</td>
                            <td>
                              <Badge color="primary" pill>
                                {el?.status}
                              </Badge>
                            </td>
                            <td>
                              {el.status == "processing" ? (
                                <>
                                  <Button size="sm" color="primary">
                                    Mark Fulfilled
                                  </Button>
                                  <Button size="sm" color="danger">
                                    Mark Unfulfilled
                                  </Button>
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}{" "}
                    </tbody>
                  </Table>
                </>
              ) : (
                <Row className="justify-content-center align-items-center py-4">
                  {" "}
                  You have no access to this page{" "}
                </Row>
              )}
            </>
          ) : (
            <>
              {" "}
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Table No.</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Comments</th>
                    <th scope="col">Created</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((el, id) => {
                    const timestamp = el?.createdAt;

                    // Convert the timestamp to a JavaScript Date object
                    const timestampDate = new Date(
                      timestamp._seconds * 1000 +
                        timestamp._nanoseconds / 1000000
                    );
                    const currentTime = moment();
                    const minutesPassed = currentTime.diff(
                      timestampDate,
                      "minutes"
                    );

                    let color = ""; // Initialize the color variable

                    if (minutesPassed < 5) {
                      color = "bg-green";
                    } else if (minutesPassed >= 5 && minutesPassed <= 10) {
                      color = "bg-orange";
                    } else {
                      color = "bg-red";
                    }
                    return (
                      <tr key={id} className={color}>
                        <th scope="row">{el?.tableNumber}</th>
                        <td>{el?.customerName}</td>
                        <td>{el?.comments}</td>
                        <td>{minutesPassed} minutes ago</td>
                        <td>
                          <Badge color="primary" pill>
                            {el?.status}
                          </Badge>
                        </td>
                        <td>
                          {el.status === "processing" ? (
                            <>
                              <Button
                                size="sm"
                                color="primary"
                                onClick={() =>
                                  handleUpdateRequest(el, "fullfilled")
                                }
                              >
                                Mark Fulfilled
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() =>
                                  handleUpdateRequest(el, "unfullfilled")
                                }
                              >
                                Mark Unfulfilled
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem disabled={currentPage === 0}>
                  <PaginationLink
                    href=""
                    onClick={() => handleClick(currentPage - 1)}
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {/* Page numbers */}
                {renderPageNumbers()}
                {/* Next button */}
                <PaginationItem
                  disabled={currentPage === restaurantRequestsRest?.nbPages - 1}
                >
                  <PaginationLink
                    href=""
                    onClick={() => handleClick(currentPage + 1)}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
            </Card>
      </div>
    </Row>
  );
}

export default RequestTable;
