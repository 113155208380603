/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import "./auth.scss";
import adminLogin from "../assets/img/adminLogin.svg";

// core components

import routes from "routes.js";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  // React.useEffect(() => {
  //   document.body.classList.add("bg-default");
  //   return () => {
  //     document.body.classList.remove("bg-default");
  //   };
  // }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div
        className={
          location.pathname !== "/auth/admin-login"
            ? "main-content-auth"
            : "admin-login"
        }
        ref={mainContent}
      >
        <div className="header content py-3 py-lg-5">
          <Container>
            <Row
              className={`justify-content-center py-3  ${
                location.pathname === "/auth/admin-login"
                  ? "shadow admin-login__row"
                  : ""
              }`}
            >
              {location.pathname === "/auth/admin-login" && (
                <Col lg="6" md="7">
                  <img src={adminLogin} alt="admin logo" className="login_img" />
                </Col>
              )}
              <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Auth;
