const initialState = {
  restaurantRequests: [],
  restaurantOrders: [],
  unreadNotifications: [],
  allNotifications: [],
  restaurantOrdersRestData: [],
  restaurantCurrency: "",
  requestLoader: false,
  orderLoader: false,
  restaurantRequestsRest: [],
};

const requestReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "REQUESTS": {
      return {
        ...state,
        restaurantRequests: payload,
      };
    }
    case "GET_ALL_REQUESTS": {
      return {
        ...state,
        restaurantRequestsRest: payload,
      };
    }
    case "UPDATE_REQUESTS": {
      let tempRestaurantRequests = JSON.parse(
        JSON.stringify(state.restaurantRequests)
      );
      tempRestaurantRequests = tempRestaurantRequests.filter(
        (request) => request.id !== payload
      );
      return {
        ...state,
        restaurantRequests: [...tempRestaurantRequests],
      };
    }

    case "ORDERS": {
      return {
        ...state,
        restaurantOrders: payload,
      };
    }
    case "ORDERS_DETAILS": {
      return {
        ...state,
        restaurantOrdersRestData: payload,
      };
    }
    case "ORDER_LOADER": {
      return {
        ...state,
        orderLoader: payload,
      };
    }
    case "UNREAD_NOTFICATIONS": {
      return {
        ...state,
        unreadNotifications: payload,
      };
    }
    case "ALL_NOTIFICATIONS": {
      return {
        ...state,
        allNotifications: payload,
      };
    }
    case "UPDATE_ORDER_STATUS": {
      const { id, status } = payload;
      let tempRestaurantOrders = JSON.parse(
        JSON.stringify(state.restaurantOrders)
      );
      const updatedOrders = tempRestaurantOrders.map((order) => {
        if (order.id === id) {
          return {
            ...order,
            status: status,
          };
        }
        return order;
      });
      return {
        ...state,
        restaurantOrders: [...updatedOrders],
      };
    }
    case "RESTAURANT_CURRENCY":
      return {
        ...state,
        restaurantCurrency: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default requestReducer;
