import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Label,
  Input,
  Form,
  FormGroup,
  Button,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { updateParent } from "store/actions/MenuManagmentActions";

const EditParentMenu = ({
  editParentMenu,
  setOffcanvas,
  menuName,
  setMenuName,
}) => {
  const dispatch = useDispatch();
  const { restaurantMedia } = useSelector((state) => state.restaurant);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [Name, setName] = useState(editParentMenu?.name);

  const Navs = ["Overview"
  //  "Availibilty"
  ];

  return (
    <>
      <div className="w-100 h-auto mt-2 bg-white shadow-sm d-flex flex-column justify-content-evenly  side-offcanvas">
        <div className="w-100 d-flex align-items-center py-4 px-4 border-bottom border-top">
          {" "}
          <i
            className="fas fa-close fa-2x cursor-pointer"
            onClick={() => setOffcanvas(false)}
          ></i>
          <h2 className="mb-0 ml-4">{menuName}</h2>
        </div>
        {/* body */}
        <div
          style={{
            padding: 10,
          }}
        >
          <Nav tabs>
            {Navs.map((nav, index) => {
              return (
                <NavItem>
                  <NavLink
                    style={{
                      border: currentActiveTab === index ? "none" : "",
                      borderBottom:
                        currentActiveTab === index
                          ? `2px solid ${
                              restaurantMedia?.adminColor || "#d70365"
                            }`
                          : "",
                      cursor: "pointer",
                      color:
                        currentActiveTab === index
                          ? `${restaurantMedia?.adminColor || "#d70365"}`
                          : "#525f7f",
                      backgroundColor:
                        currentActiveTab === index ? "#ffffff" : "transparent",
                    }}
                    className={classnames({
                      active: currentActiveTab === index,
                    })}
                    onClick={() => {
                      setCurrentActiveTab(index);
                    }}
                  >
                    {nav}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
          <TabContent activeTab={currentActiveTab} className="my-3">
            <TabPane tabId={0}>
              <Row>
                <Col>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      dispatch(
                        updateParent(editParentMenu?.id, Name, () => {
                          setMenuName(Name);
                          setOffcanvas(false);
                        })
                      );
                    }}
                  >
                    <FormGroup>
                      <Label for="exampleEmail" className="mb-1">
                        <span className="text-danger text-center">*</span> Name
                      </Label>
                      <Input
                        id="exampleEmail"
                        name="Name"
                        placeholder="Sample Menu"
                        type="text"
                        value={Name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FormGroup>
                    <Button
                      type="submit"
                      className="float-right"
                      size="sm"
                      disabled={Name == ""}
                      color="primary"
                    >
                      Update
                    </Button>
                  </Form>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={1}>
              <Row>
                <Col sm="12">
                  <h5>Sample Tab 2 Content</h5>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default EditParentMenu;
