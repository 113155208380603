
import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { updateRestaurantCurrency } from "store/actions/settingAction";

const RestaurantCurrency = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.auth);
  const { currencyLoading } = useSelector((state) => state.setting);

  const [selectedCurrency, setSelectedCurrency] = useState("");

  const currencyOptions = [
    { value: "$", label: "USD" },
    { value: "€", label: "EUR" },
    { value: "£", label: "GBP" },
    { value: "¥", label: "JPY" },
  ];

  function handleCurrencyChange(selectedOption) {
    const selectedValues = selectedOption.value;
    setSelectedCurrency(selectedValues);
  }
  useEffect(() => {
    if (user?.currency) {
      setSelectedCurrency(user?.currency);
    } else {
      setSelectedCurrency("");
    }
  }, [user]);

  const handleCurrencySubmit = (evt) => {
    evt.preventDefault();
    dispatch(updateRestaurantCurrency(uid, selectedCurrency));
  };

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7 mb-5" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="d-lg-flex  d-sm-block justify-content-between">
                <div className="d-flex align-items-center">
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => {
                      history.push("/admin/venue-setting-menu");
                    }}
                  >
                    <i className="fas fa-arrow-left "></i>
                  </Button>

                  <h3 className=" pt-2 ">Restaurant Currency</h3>
                </div>
              </CardHeader>

              <div className="mx-3 mx-md-5 my-4">
                {/* <h3 className=" pt-2 ">Restaurant Currency</h3> */}
                <Form onSubmit={handleCurrencySubmit}>
                  <FormGroup>
                    <Label for="languages">Select Currency:</Label>
                    <Select
                      value={currencyOptions.filter((option) =>
                        selectedCurrency.includes(option.value)
                      )}
                      placeholder="Select Currency"
                      options={currencyOptions}
                      onChange={handleCurrencyChange}
                    />
                  </FormGroup>

                  <div className="d-flex justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={currencyLoading}
                    >
                      {currencyLoading ? <Spinner size="sm" /> : "Save"}
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default RestaurantCurrency;
