const initState = {
    faqsLoader:false,
    faqs:null,
  };
  const faqsReducer = (state = initState, action) => {
    switch (action.type) {
      case "GET_FAQS":
        return {
          ...state,
          faqs: action.payload,
        };
        case 'FAQS_LOADER':
          return {
            ...state,
            faqsLoader:action.payload,
          }
      default:
        return {
          ...state,
        };
    }
  };
  export default faqsReducer;
  