import React, { useState } from "react";
import ReactSlider from "react-slider";
import { toast } from "react-toastify";
import { Input } from "reactstrap";

const OrderRangeSlider = ({ value, setValue, setCurrentPage }) => {
  const [inputValues, setInputValues] = useState(value);

  const handleInputChange = (e, index) => {
    const inputValue = e.target.value.trim(); // Trim any leading/trailing whitespace

    if (inputValue === "") {
      const newInputValues = [...inputValues];
      newInputValues[index] = 0; // Set the value to an empty string

      setInputValues(newInputValues);
      setValue(newInputValues); // Update the value prop
      return;
    }

    const parsedValue = parseInt(inputValue);

    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > 10000) {
      // toast.error("Incorrect Value");
      setInputValues(value); // Restore the previous input values
      return;
    }

    const newInputValues = [...inputValues];
    newInputValues[index] = parsedValue;

    if (index == 0) {
      if (newInputValues[0] > newInputValues[1]) {
        toast.error("First value should be smaller than the second value");
        setInputValues(inputValues); // Restore the previous input values
        return;
      }
    } else {
      if (newInputValues[0] > newInputValues[1]) {
        newInputValues[0] = 0;
        setInputValues(newInputValues);
        setValue(newInputValues); // Update the value prop
        return;
      }
    }

    setInputValues(newInputValues);
    setValue(newInputValues); // Update the value prop
  };

  // const handleInputChange = (e, index) => {
  //   const inputValue = parseInt(e.target.value);

  //   if (inputValue >= value[0] && inputValue <= value[1]) {
  //     const newInputValues = [...inputValues];
  //     newInputValues[index] = inputValue;

  //     if (index === 0 && newInputValues[0] >= newInputValues[1]) {
  //       toast.error("First value should be smaller than the second value");
  //       setInputValues(inputValues); // Restore the previous input values
  //       return;
  //     }

  //     setInputValues(newInputValues);
  //     setValue(newInputValues); // Update the value prop
  //   } else {
  //     toast.error("Incorrect Value");
  //     setInputValues(value); // Restore the previous input values
  //   }
  // };

  const handleSliderChange = (newValue, index) => {
    setCurrentPage(0);
    setValue(newValue, index);
    setInputValues(newValue);
  };

  return (
    <>
      <div className="slider-div">
        <Input
          type="number"
          min={0}
          max={10000}
          className="no-spin"
          value={inputValues[0]}
          onChange={(e) => handleInputChange(e, 0)}
          onBlur={(e) => handleInputChange(e, 0)} // Added onBlur event handler
        />
        <ReactSlider
          value={value}
          onChange={(value, index) => handleSliderChange(value, index)}
          onAfterChange={(value, index) => handleSliderChange(value, index)}
          className="horizontal-slider"
          max={10000}
          min={0}
          thumbClassName="example-thumb"
          trackClassName="example-track"
          renderThumb={(props, state) => <div {...props}></div>}
        />
        <Input
          type="number"
          min={0}
          // max={value[1]}
          max={10000}
          className="no-spin"
          value={inputValues[1]}
          onChange={(e) => handleInputChange(e, 1)}
          onBlur={(e) => handleInputChange(e, 1)} // Added onBlur event handler
        />
      </div>
    </>
  );
};

export default OrderRangeSlider;
