import React from "react";
import { Col, Container, Row } from "reactstrap";
import denny from "../../assets/img/LandingPage/Denny's.png";
import outback from "../../assets/img/LandingPage/outback.png";
import arbys from "../../assets/img/LandingPage/arbys.png";
import Panda from "../../assets/img/LandingPage/panda.png";
import olive from "../../assets/img/LandingPage/olive.webp";

const Industries = () => {
  const CardContent = [
    {
      url: Panda,
      name: "Panda Express",
      desc: "At Panda Express, we take pride in offering an exquisite selection of Chinese cuisine that captures the essence of China's diverse culinary traditions.",
    },
    {
      url: arbys,
      name: "Arby’s",
      desc: "At Arby's, we invite you on a gastronomic journey where culinary boundaries blur and flavours meld in harmony.",
    },
    {
      url: outback,
      name: "Outback Steakhouse",
      desc: "At Outback Steakhouse, we proudly bring the rich and diverse flavours of Italy to your table.",
    },
    {
      url: denny,
      name: "Denny's",
      desc: "Welcome to Denny's, your go-to destination for the true taste of America.",
    },
    {
      url: olive,
      name: "Olive Garden Italian Restaurant",
      desc: "At Olive Garden, we pride ourselves on delivering a genuine taste of Italy.",
    },
  ];
  return (
    <Container className="my-5" fluid>
      <Row className="d-flex justify-content-center">
        <Col className=" industries mx-3 my-4 col-10 col-sm-5 col-md-5 col-lg-3">
          <div className="industries_title">
            <h1>Our Happy Clients</h1>
          </div>
        </Col>
        {CardContent?.map((item,index) => {
          return (
            <Col key={index}
              className="col-10 col-sm-5 col-md-5 col-lg-3 industries mx-3 my-4 "
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <div className="hover__background">
                {/* <img
                  src={leaves}
                  alt=""
                  className="hover__background__leaves"
                /> */}
                <p className="hover__background__text">{item?.desc}</p>
              </div>
              <div className="industries_main">
                <img src={item?.url} alt="item" className="bg-white" />
                <div className="industries_main_overlay"></div>
                <h4 className="industries_main_text text-capitalize">
                  {item?.name}
                </h4>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Industries;
