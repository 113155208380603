import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { useDispatch, useSelector } from "react-redux";
import LandingLayout from "layouts/LandingLayout";
import AOS from "aos";
import "aos/dist/aos.css";
import { withProfiler } from '@sentry/react';

function App() {
  let { uid } = useSelector((state) => state.auth);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        {uid ? (
          <Switch>
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
            <Redirect from="/" to="/admin" />
          </Switch>
        ) : (
          <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route path="/" render={(props) => <LandingLayout {...props} />} />
            {/* <Redirect from="/" to="/" /> */}
          </Switch>
        )}
      </BrowserRouter>
    </>
  );
}

export default withProfiler(App);
