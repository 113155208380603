/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
// reactstrap components
import { Container } from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useDispatch} from "react-redux";
import AddCheckoutQuestionModal from "components/Modals/AddCheckoutQuestionModal";
import { useParams } from "react-router-dom";
import { getCheckoutResponses } from "store/actions/checkoutQuestionAction";
import CheckoutResponsesTable from "components/CheckoutResponses/CheckoutResponsesTable";

const CheckoutResponses = () => {
  const [addModal, setAddModal] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const addToggle = () => {
    setAddModal(!addModal);
  };

  useEffect(() => {
    dispatch(getCheckoutResponses(id));
  }, [id]);

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        <CheckoutResponsesTable addToggle={addToggle} />
        <AddCheckoutQuestionModal addModal={addModal} addToggle={addToggle} />
      </Container>
    </>
  );
};

export default CheckoutResponses;
