import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  Label,
} from "reactstrap";
import Mpink from "../../assets/img/Mpink.png";
// import CryptoJS from 'crypto-js';
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { adminSignUp } from "store/actions/authActions";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { RepositoryFactory } from "repository/RepositoryFactory";
import { createNewSubscription } from "store/actions/subscriptionAction";
let Payment = RepositoryFactory.get("payment");
const SignUp = () => {
  const history = useHistory();

  // let stripePromise = loadStripe("pk_test_7wzXL7byFHbRdehCSS5eC04Q00zUcStdHz");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eye, setEye] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const validateCredentials = () => {
    if (
      email.includes(" ") ||
      !/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(password) ||
      password.length < 6 ||
      password.includes(" ") ||
      !/\d/.test(password)
    ) {
      setError({
        ...error,
        password:
          "Password must have a special character, a digit and it should be 6 digit long",
      });
      return false;
    } else {
      return true;
    }
  };

  const SignUpAction = async () => {
    setPaymentLoader(true);
    let restaurantID = "";
    let planId = "";
    await Payment.getPaymentPrices()
      .then((res) => {
        let { data } = res;
        data?.data?.forEach((record) => {
          let nickname = record?.recurring?.interval;
          if (nickname === subscriptionType) {
            planId = record?.id;
          }
        });
      })
      .catch((err) => {
        setPaymentLoader(false);
        let { data } = err.response;
        console.error(data?.error, "Error");
        setPaymentLoader(false);
      });
    setPaymentLoader(false);

    let obj = {
      email,
    };

    dispatch(
      adminSignUp(obj, password, async (data) => {
        console.log(obj, "obj");
        restaurantID = data?.user?.uid;
        localStorage.setItem(
          "encryptedPassword",
          JSON.stringify({ email: obj.email, password: password })
        );
        let body = {
          plan: subscriptionType,
          restaurantID,
          priceId: planId,
        };
        dispatch(
          createNewSubscription(body, () => {
            setEmail("");
            setPaymentLoader(false);
            setSubscriptionType("");
            setPassword("");
          })
        );
      })
    );
  };
  return (
    <Col lg="5" md="7" className="login">
      <Card className="bg-secondary shadow border-0    ">
        <CardBody className="px-lg-5 pt-lg-2 pb-lg-2">
          <div className=" d-flex flex-column justify-content-center align-items-center">
            <img
              src={Mpink}
              alt="logo"
              onClick={() => history.push("/")}
              className="mb-1 w-25"
            />
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h3 className="title">Sign Up</h3>
            </div>
          </div>
          <Form
            onSubmit={(e) => {
              // setTab(2);
              e.preventDefault();
              if (validateCredentials()) {
                SignUpAction();
              }
            }}
          >
            <Row className="py-2">
              <Col className="col-6 pr-2">
                <FormGroup className="mb-1">
                  <Label className="form_label">Merchant Email</Label>
                  <Input
                    placeholder="example@gmail.com"
                    type="email"
                    autoComplete="new-email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {error?.email ? (
                    <span className="text-danger" style={{ fontSize: "11px" }}>
                      {error.email}
                    </span>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </Col>
              <Col className="col-6 pl-2">
                <FormGroup className="mb-1">
                  <Label className="form_label">Password</Label>
                  <div className="login__password">
                    <Input
                      placeholder="Password"
                      // type="password"
                      required
                      type={eye ? "text" : "password"}
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {eye ? (
                      <FaEyeSlash
                        className="eye_icon"
                        onClick={() => setEye(!eye)}
                      />
                    ) : (
                      <FaEye
                        className="eye_icon"
                        onClick={() => setEye(!eye)}
                      />
                    )}
                  </div>
                  {error?.password ? (
                    <span className="text-danger" style={{ fontSize: "11px" }}>
                      {error.password}
                    </span>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </Col>
            </Row>
            <FormGroup className="mb-3">
              <Label className="form_label">Subscription Type</Label>
              <Input
                type="select"
                required
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
              >
                <option value="">--select--</option>
                <option value="month">Monthly</option>
                <option value="year">Yearly</option>
              </Input>
            </FormGroup>

            <Button
              className="mt-1 mb-1 w-100 login__btn"
              type="submit"
              disabled={!email || !subscriptionType || !password}
              dispatch={subscriptionType}
            >
              {paymentLoader ? <Spinner size={"sm"} /> : "Sign Up"}
            </Button>

            <Row className="mt-0 mb-2 mx-2 justify-content-end">
              <Link to="/auth/login" className="pointer">
                <small>Already have an account? login</small>
              </Link>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SignUp;
