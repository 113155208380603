import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { deleteForwardFee } from "store/actions/settingAction";
import { updateForwardFees } from "store/actions/settingAction";

const PaymentConnectionTable = ({
  forwardFee,
  currencyLoading,
  paymentConnectionLoading,restaurantCurrency
}) => {
  const dispatch = useDispatch();
  const { user, uid } = useSelector((state) => state.auth);

  const deleting = paymentConnectionLoading ? true : false;

  const [toUpdate, setToUpdate] = useState({
    fixedAmount: "",
    percentageAmount: "",
    provider: "",
    type: "",
  });

  const [modal, setModal] = useState(false);
  const [toDelete, setToDelete] = useState({});
  const toggle = () => setModal(!modal);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setToUpdate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleDelete = (event, objToDelete) => {
    event.preventDefault();
    dispatch(deleteForwardFee(objToDelete, user?.restaurantID));
  };

  const handleUpdate = (event, objToUpdate) => {
    event.preventDefault();

    dispatch(
      updateForwardFees(user?.restaurantID, objToUpdate, () => {
        toggle();
      })
    );
  };
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Payment Method</th>
            <th scope="col">Payment Type</th>
            <th scope="col">Percentage </th>
            <th scope="col">Fixed Fee</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {forwardFee &&
            forwardFee.map((el, id) => {
              return (
                <tr key={id}>
                  <td> {el?.provider}</td>
                  <td> {el?.type}</td>
                  <td> {el?.percentageAmount}</td>
                  <td> {el?.fixedAmount}{" "}{restaurantCurrency}</td>

                  <td>
                    <Button
                      className="btn-sm"
                      color="primary"
                      disabled={deleting}
                      onClick={() => {
                        setToUpdate(el);
                        toggle();
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      className="btn-sm"
                      color="danger"
                      onClick={(event) => {
                        setToDelete(el);
                        handleDelete(event, el);
                      }}
                    >
                      {el.provider == toDelete.provider
                        ? paymentConnectionLoading
                          ? "Deleting..."
                          : "Delete"
                        : "Delete"}
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {/* Modal for update  */}
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Update Payment Method</ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(event) => {
                let obj = {
                  fixedAmount: "",
                  percentageAmount: "",
                  provider: toUpdate?.provider,
                  type: toUpdate?.type,
                };

                if (toUpdate?.type === "both") {
                  obj.fixedAmount = toUpdate.fixedAmount;
                  obj.percentageAmount = toUpdate.percentageAmount;
                } else if (toUpdate?.type === "percentage") {
                  obj.percentageAmount = toUpdate?.percentageAmount;
                } else {
                  obj.fixedAmount = toUpdate?.fixedAmount;
                }
                handleUpdate(event, obj);
              }}
            >
              <FormGroup>
                <Label for="role">Payment Method</Label>
                <Input
                  type="text"
                  name="provider"
                  id="provider"
                  disabled
                  value={toUpdate?.provider}
                />
              </FormGroup>

              <FormGroup>
                <Label for="type">Payment Type</Label>
                <Input
                  type="select"
                  name="type"
                  id="type"
                  className="mx-1"
                  value={toUpdate?.type}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Payment Type
                  </option>
                  <option value="percentage">Percentage</option>
                  <option value="fixed_price">Fixed Price</option>
                  <option value="both">Both</option>
                </Input>
              </FormGroup>

              {(toUpdate?.type == "percentage" || toUpdate?.type == "both") && (
                <FormGroup>
                  <Label for="percentageAmount">Percentage Amount</Label>
                  <Input
                    type="number"
                    min={0}
                    step="any"
                    name="percentageAmount"
                    id="percentageAmount"
                    value={toUpdate?.percentageAmount}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              )}

              {(toUpdate?.type == "fixed_price" ||
                toUpdate?.type == "both") && (
                <FormGroup>
                  <Label for="fixedAmount">Fixed Amount</Label>
                  <Input
                    type="number"
                    min={0}
                    step="any"
                    name="fixedAmount"
                    id="fixedAmount"
                    value={toUpdate?.fixedAmount}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              )}

              <div
                className="d-flex justify-content-end"
                disabled={paymentConnectionLoading}
              >
                <Button color="primary">
                  {currencyLoading ? "Updating..." : "Update"}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default PaymentConnectionTable;
