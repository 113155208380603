import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import { RepositoryFactory } from "repository/RepositoryFactory";
let User = RepositoryFactory.get("user");
// Import the "Loader" action if not already done

export const addNewUser =
  (payload, permissions, onSuccess = () => {}) =>
  async (dispatch) => {
    console.log(payload, permissions, "data add");

    try {
      dispatch(Loader(true));
      const { data } = await User.add(payload);
      if (data.success) {
        if (Object.keys(permissions).length > 0) {
          await firebase
            .firestore()
            .collection("permissions")
            .add({
              userID: data.data.id,
              ...permissions,
            });
        }
        onSuccess();
        dispatch({
          type: "STAFF_ADDED",
          payload: data.data,
        });

        dispatch(Loader(false));
      } else {
        console.log("errr", data);
        alert(data.message);
        dispatch(Loader(false));
      }
    } catch (error) {
      // console.log("error", error);
      toast.error(error?.response?.data?.message);
      dispatch(Loader(false)); // Set loading state to false
    }
  };

export const login = (creds) => {
  return (dispatch) => {
    dispatch({
      type: "LOGIN_REQUEST",
    });
    firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then(async (data) => {
        const UID = data.user.uid;
        var user=data.user;
        console.log(user,"user>>>>>>>>>>");
        if(user.emailVerified || creds.email==='testadmin@gmail.com' ){
        await firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((res) => {
            dispatch(reRegisterSnapshot(UID, res?.claims?.role));
          });
        }else{
          throw new Error("Email not Verified");
        }
      })
      .catch((error) => {
        dispatch({
          type: "LOGIN_FAIL",
          uid: "",
          error: error,
        });
        dispatch({
          type: "LOGIN_REQUEST_END",
        });
        dispatch(logout());
        toast.warning(error.message);
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    firebase
      .auth()
      .signOut()
      .then((data) => {
        dispatch({
          type: "LOGOUT",
        });
        localStorage.clear();
      })
      .catch((error) => {
        dispatch({
          type: "LOGOUT_FAIL",
          uid: "",
          error: error,
        });
      });
  };
};

export const forgetPassword =
  (email, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(forgetLoader(true));
    try {
      await firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then((res) => {
          dispatch(forgetLoader(false));
          toast.success("Please check your email and reset the password");
          onSuccess();
        })
        .catch((err) => {
          dispatch(forgetLoader(false));
          toast.error(err.message);
        });
    } catch (error) {
      dispatch(forgetLoader(false));
      toast.error(error.message);
    }
  };

export const reRegisterSnapshot = (id, role) => async (dispatch) => {
  firebase
    .firestore()
    .collection("users")
    .doc(id)
    .onSnapshot(async (doc) => {
      let user = doc.data();
      let isRoleValid =
        role == "kitchen-cook" ||
        role == "kitchen-admin" ||
        role == "restaurant" ||
        role == "admin" ||
        role == "sub-admin";

      if (isRoleValid) {
        if (role == "kitchen-cook" || role == "kitchen-admin") {
          dispatch(getPermissions(doc.id));
        }
        if (role == "restaurant") {
          if (user?.subscription?.subscriptionID) {
            await User.getSubscriptionStatus(user?.subscription?.subscriptionID)
              .then((res) => {
                console.log(res, "<<<getSubscriptionStatus");
              })
              .catch((ex) => {
                toast.error(ex.response.data.error);
              });
          }
        }
        dispatch({
          type: "LOGIN_SUCCESS",
          user: { id: doc.id, ...doc.data() },
          error: "",
        });
        dispatch({
          type: "LOGIN_REQUEST_END",
        });
      } else {
        toast.error("You are not permitted to access the platform");
        dispatch(logout());
      }
    });
};

export const getPermissions = (id) => async (dispatach) => {
  let permissionsData = {};
  firebase
    .firestore()
    .collection("permissions")
    .where("userID", "==", id)
    .limit(1)
    .get()
    .then(async (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        permissionsData = { id: doc.id, ...doc.data() };
      });
      await dispatach({
        type: "PERMISSIONS",
        payload: permissionsData,
      });
    })
    .catch((error) => {
      console.error("Error getting documents: ", error);
    });
};

export const updateColors = (id, payload) => async (dispatch) => {
  await firebase
    .firestore()
    .collection("users")
    .doc(id)
    .update({ ...payload })
    .then(toast.success("Restaurant colors updated"));
};

const Loader = (data) => async (dispatch) => {
  dispatch({
    type: "CATALOG_LOADER",
    payload: data,
  });
};

const forgetLoader = (data) => async (dispatch) => {
  dispatch({
    type: "FORGET_LOADER",
    payload: data,
  });
};
export const adminSignUp =
  (payload, password, onSuccess = (data) => {}) =>
  async (dispatch) => {
    console.log({ payload });

    // dispatch(signupLoader(true));
    firebase
      .auth()
      .createUserWithEmailAndPassword(payload.email, password)
      .then((data) => {
        firebase
          .firestore()
          .collection("users")
          .doc(data.user?.uid)
          .set({
            ...payload,
            firsttimelogin: true,
            restaurantID: data?.user?.uid,
            isDeleted: false,
            status: "active",
            subScriptionStatus: "subscribe",
            currency: "$",
            language: "en",
            type: "restaurant",
            restaurantLogo: "",
            socialProfiles: [
              {
                type: "facebook",
              },
              {
                type: "instagram",
              },
              {
                type: "tiktok",
              },
              {
                type: "discord",
              },
              {
                type: "youtube",
              },
              {
                type: "website",
              },
              {
                type: "linktree",
              },
              {
                type: "linkedIn",
              },
              {
                type: "snapchat",
              },
              {
                type: "twiter",
              },
            ],
            storeTiming: [
              { day: "Monday", startTime: "", endTime: "" },
              { day: "Tuesday", startTime: "", endTime: "" },
              { day: "Wednesday", startTime: "", endTime: "" },
              { day: "Thursday", startTime: "", endTime: "" },
              { day: "Friday", startTime: "", endTime: "" },
              { day: "Saturday", startTime: "", endTime: "" },
              { day: "Sunday", startTime: "", endTime: "" },
            ],
            createdAt: firebase.firestore.Timestamp.now(),
          })
          .then((res) => {
            console.log(data, "adminsignup");
            onSuccess(data);
          })
          .catch((err) => {
            // dispatch(signupLoader(false));
            toast.error(err.message);
          });
      })
      .catch((err) => {
        // dispatch(signupLoader(false));
        toast.error(err.message);
      });
  };
export const CloseAlert = (id, onDismiss) => async (dispatch) => {
  console.log(id, "id>>>>>");
  try {
    await firebase
      .firestore()
      .collection("users")
      .doc(id)
      .update({
        firsttimelogin: false,
      })
      .then(() => onDismiss()); // Call onSuccess callback upon success
  } catch (error) {
    console.error("Error closing alert:", error);
  }
};
