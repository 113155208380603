import React from "react";
import { useHistory } from "react-router-dom";
const LandingFooter = () => {
  const history=useHistory();
  function getCurrentYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear;
  }
  return (
    <div className="copyright-area">
    <div className="container">
        <div className="row">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                    <p>Copyright &copy; {getCurrentYear()}{" "} NextEats, All Right Reserved <a href="https://nexteats-admin.web.app/"><img src="https://res.cloudinary.com/dzhvjtoot/image/upload/v1712309249/logo/ogb3puh0buzqtzem3ce1.png" width={17} height={17}/></a></p>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div className="footer-menu">
                    <ul>
                        <li> <a href="email: support@nexteats.com">support@nexteats.com</a></li>
                        <li><a href="#">Privacy</a></li>
                        <li><a href="#">Policy</a></li>
                        <li><a className="cursor-pointer" onClick={()=>history.push('/contact')}>Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
        </div>
  );
};

export default LandingFooter;
