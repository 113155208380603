import EmailRepository from "./EmailRepository";
import StatsRepository from "./StatsRepository";
import UserRepository from "./UserRepository";
import PaymentRepository from "./PaymentRepository";
const repositories = {
  user: UserRepository,
  stats: StatsRepository,
  email: EmailRepository,
  payment: PaymentRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
