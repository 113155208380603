
import csvtojson from "csvtojson";
import { useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import menuFile from "../../assets/csvFile/CatergoryAndItem.csv";
import deleteIcon from "../../assets/img/icons/delete.svg";
import edit from "../../assets/img/icons/edit.svg";
import fileupload from "../../assets/img/icons/fileupload.svg";

import { useHistory } from "react-router-dom";

import {
  addCategory,
  editCategory,
  editMenu,
  getParentMenu,
} from "store/actions/MenuManagmentActions";

import SwitchToggler from "components/SwitchToggler";
import RichEditor from "components/TextEditor/RichEditor";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  addMenu,
  addlabel,
  deleteCategoryNew,
  deleteItem,
  duplicateCategory,
  duplicateItem,
  getLabels,
  importMenu,
} from "store/actions/MenuManagmentActions";
import Logo from "../../assets/img/Mpink.png";

const EditScratchMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, userPermissions } = useSelector((state) => state.auth);
  const { restaurantMedia } = useSelector((state) => state.restaurant);
  const {
    labelsData,
    editLabelLoader,
    addMenuLoader,
    categoryLoader,
    editMenuData,
  } = useSelector((state) => state.menu);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [hoverColor, setHoverColor] = useState("#d70365");

  const menuPermissions = userPermissions?.menus;
  const CategoriesRef = useRef(null);
  const itemsRef = useRef(null);

  const pathParts = window.location.href.split("/");
  const menuID = pathParts[pathParts.length - 1];

  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [customOffcanvas, setCustomOffcanvas] = useState(false);
  const [categoryImage, setCategoryImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoverIndex, setIsHoverIndex] = useState(-1);

  const [name, setName] = useState("");
  const [categoriesAndItems, setCategoriesAndItems] = useState([]);
  const [toEdit, setToEdit] = useState("");
  const [labelDescription, setLabelDescription] = useState("");
  const [labelName, setlabelName] = useState("");
  const [categoryImagesToRemove, setCategoryImageToRemove] = useState("");
  const [imagesToRemove, setImagesToRemove] = useState([]);
  const [saveAndAddMore, setSaveAndAddMore] = useState(false);

  const [hoverDelete, setHoverDelete] = useState();
  const [colorIcon, setColorIcon] = useState();
  const [hoverEdit, setHoverEdit] = useState();
  const [colorIconEdit, setColorIconEdit] = useState();
  const [item, setItem] = useState("");
  const [comments, setComments] = useState("");
  const [category, setCategory] = useState("");
  const [calories, setCalories] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [estimatedTime, setEstimatedTime] = useState("");
  const [Items, setItems] = useState([]);
  const [subItem, setSubItem] = useState("");
  const [price, setPrice] = useState("");
  const [secondTableItems, setSecondTableItems] = useState([]);
  const [label, setLabel] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [images, setImages] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const [productOptions, setProductOptions] = useState([]);
  const [showRecommended, setshowRecommended] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [recommendedIndex, setRecommendedIndex] = useState(-1);

  const [hoverColorMenu, setHoverColorMenu] = useState();
  const [colorText, setColorText] = useState("#32325d");
  const [textColorMenu, setTextColorMenu] = useState("#32325d");
  const [droppedFile, setDroppedFile] = useState(null);
  const [id, setId] = useState();
  const [isDragOver, setIsDragOver] = useState(false);

  const [isActiveMenu, setIsActiveMenu] = useState(false);
  const [isActiveMenuItems, setIsActiveMenuItems] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

  const [addModal, setAddModal] = useState(false);
  const addtoggle = () => {
    setAddModal(!addModal);
  };
  const { menuData, labelsRestData, addLabelLoader } = useSelector(
    (state) => state.menu
  );

  const fileRef = useRef();
  const handleClickMenu = () => {
    fileRef.current.click();
  };

  const handleImport = (event) => {
    const file = event.target.files[0];
    const fileName = file.name.split(".")[1];
    if (fileName === "csv") {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = async () => {
        try {
          const importedData = await csvtojson().fromString(reader.result);

          if (importedData?.length > 0) {
            const promises = importedData.map(async (data) => {
              let sizes = [];
              let sizesColumns = data?.Sizes_price_calories.split(",");
              for (let singleSize of sizesColumns) {
                let ItemsSizes = singleSize?.split("_");
                let obj = {
                  subItem: ItemsSizes[0],
                  price: ItemsSizes[1],
                  calories: ItemsSizes[2],
                };
                sizes.push({ ...obj });
              }
              let item = {
                name: data?.itemName,
                estimatedTime: data?.EstTime,
                restaurantID: user?.restaurantID,
                menuID,
                categoriesID: category?.id || category?.categoriesID,
                isDeleted: false,
                isVisible: true,
                sizes,
                recommendedProducts: [],
                label: [],
                images: [],
                comments: "",
                views: 0,
              };

              return new Promise((resolve) => {
                dispatch(
                  importMenu(item, (res) => {
                    const updatedCategoriesAndItems = [...categoriesAndItems];

                    if (category) {
                      if (category?.categoriesID) {
                        const categoryObj = updatedCategoriesAndItems.find(
                          (ele) => ele.categoriesID === category.categoriesID
                        );
                        categoryObj.items = [res, ...categoryObj.items];
                        setProductOptions(
                          categoryObj?.items?.map((item) => {
                            return {
                              value: `${item?.name}`,
                              label: `${item?.name}`,
                            };
                          })
                        );
                        categoryObj?.items?.length > 0
                          ? setshowRecommended(true)
                          : setshowRecommended(false);
                      } else {
                        const categoryObj = updatedCategoriesAndItems?.find(
                          (ele) => ele.id === res.categoriesID
                        );
                        categoryObj.items = [res, ...categoryObj.items];
                        setProductOptions(
                          categoryObj?.items?.map((item) => {
                            return {
                              value: `${item?.name}`,
                              label: `${item?.name}`,
                            };
                          })
                        );
                        categoryObj?.items?.length > 0
                          ? setshowRecommended(true)
                          : setshowRecommended(false);
                      }
                    } else {
                      updatedCategoriesAndItems.push(res);
                    }
                    setCategoriesAndItems(updatedCategoriesAndItems);
                    resolve();
                  })
                );
              });
            });

            await Promise.all(promises);

            toast.success("Menu Imported Successfully");
            setCategory("");
          } else {
            toast.error("Nothing to import");
          }
        } catch (error) {
          toast.error(error);
        }
      };
    } else {
      toast.error("Select the CSV file");
    }
  };

  useEffect(() => {
    const filteredMenuData = menuData?.filter((item) => !item.isDeleted);
    const uniqueProductOptions = [
      ...new Set(
        filteredMenuData?.map((item) => ({ id: item.id, name: item.name }))
      ),
    ];
    setProductOptions(
      uniqueProductOptions.map((option) => ({
        id: option.id,
        value: option.name,
        label: option.name,
      }))
    );

    setshowRecommended(uniqueProductOptions.length > 0);
  }, [menuData]);

  const handleItemChange = (e, index, field) => {
    const newItems = [...Items];
    newItems[index][field] = e.target.value;
    setItems(newItems);
  };
  const handleChangeImage = (e) => {
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "image/svg",
      "image/webp",
    ];

    if (e.target.files.length) {
      const file = e.target.files[0];
      const fileType = file.type;

      if (allowedTypes.includes(fileType)) {
        setImage({
          preview: URL.createObjectURL(file),
          raw: file,
        });
      } else {
        toast.error(
          "Invalid file type. Please use a jpeg, png, gif, jpg, svg, webp file"
        );
      }
    }
  };

  const handleUpdateClick = (index, item) => {
    const updatedItems = [...Items];
    const updatedItem = updatedItems[index];
    updatedItem.subItem = item?.subItem;
    updatedItem.price = item?.price;

    setItems(updatedItems);
    setEditingIndex(-1);
  };

  const handleRecommededChange = (index, field, value) => {
    const updatedItems = [...secondTableItems];
    updatedItems[index][field] = value;
    setSecondTableItems(updatedItems);
  };

  const handleRecommededUpdate = (index, value) => {
    const updatedItems = [...secondTableItems];
    const updatedItem = { ...updatedItems[index] };

    updatedItem.selectedOptions = value.selectedOptions;

    updatedItems[index] = updatedItem;
    setSecondTableItems(updatedItems);
    setRecommendedIndex(-1);
  };

  const fileInput = useRef(null);

  const handleImageClick = () => {
    fileInput.current.click();
  };

  const handleDropCategory = (file) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    const maxSizeInKB = 2048;
    if (!allowedExtensions.exec(file?.name)) {
      toast.error(
        "Invalid file type. Please upload only JPG, JPEG or PNG images."
      );
      return;
    }
    if (file.size > maxSizeInKB * 1024) {
      toast.warn("you  upload  only  2mb  picture");
    } else {
      try {
        Resizer.imageFileResizer(
          file,
          300,
          300,
          "JPEG",
          70,
          0,
          async (compressedImage) => {
            try {
              const response = await fetch(compressedImage);
              const compressedImageBlob = await response.blob();
              const compressedFile = new File(
                [compressedImageBlob],
                file.name,
                {
                  type: `image/${file.name.split(".")[1]}`,
                  lastModified: file.lastModified,
                  lastModifiedDate: file.lastModifiedDate,
                }
              );
              setCategoryImage(compressedFile);

              setDroppedFile(compressedFile);
            } catch (error) {
              toast.error("Failed to compress the image.");
              return;
            }
          },
          "base64"
        );
      } catch (error) {
        toast.error("Failed to compress the image.");
        return;
      }
    }
  };
  const handleFileChange = (event) => {
    setCategoryImageToRemove(categoryImage || "");
    const file = event.target.files[0];
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    const maxSizeInKB = 2048;

    if (!allowedExtensions.exec(file?.name)) {
      toast.error(
        "Invalid file type. Please upload only JPG, JPEG, or PNG images."
      );
      fileInput.current.value = "";
      return;
    }

    if (file.size > maxSizeInKB * 1024) {
      toast.warn("you  upload  only  2mb  picture");
    } else {
      try {
        Resizer.imageFileResizer(
          file,
          300,
          300,
          "JPEG",
          70,
          0,
          async (compressedImage) => {
            try {
              const response = await fetch(compressedImage);
              const compressedImageBlob = await response.blob();
              const compressedFile = new File(
                [compressedImageBlob],
                file.name,
                {
                  type: `image/${file.name.split(".")[1]}`,
                  lastModified: file.lastModified,
                  lastModifiedDate: file.lastModifiedDate,
                }
              );
              setCategoryImage(compressedFile);
            } catch (error) {
              toast.error("Failed to compress the image.");
              return;
            }
          },
          "base64"
        );
      } catch (error) {
        toast.error("Failed to compress the image.");
        return;
      }
    }

    setDroppedFile(null);
  };

  const handleRemove = (arr, index) => {
    if (arr === "subItems") {
      let filterItems = Items.filter((x, i) => i !== index);
      setItems(filterItems);
    } else if (arr === "recommended") {
      let filterItems = secondTableItems.filter((x, i) => i !== index);
      setSecondTableItems(filterItems);
    }
  };

  const imagesHandleChange = async (e) => {
    const files = e.target.files;
    const newImages = [];
    const newPreviewImages = [];
    const validFormats = ["image/jpeg", "image/jpg", "image/png"];
    const maxSizeInKB = 2048;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = file.type;

      if (!validFormats.includes(fileType)) {
        toast.error(
          `Error: Invalid file format ${file?.name}. Please select a .jpg, .jpeg, or .png image.`
        );
        continue;
      }

      var reader = new FileReader();
      reader.readAsDataURL(file);
      let height=0;
      let width=0;
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
       image.onload = function () {
           height = this.height;
           width = this.width;
        };
      };
      if (height > 800 || width > 800) {
        toast.warning("Height and Width must not exceed 800px.");
       continue;
      }


      if (file.size > maxSizeInKB * 1024) {
        toast.warn("You can only upload a 2MB picture.");
        continue;
      }

      try {
        const compressedImage = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            300,
            300,
            "JPEG",
            70,
            0,
            (compressedImage) => {
              resolve(compressedImage);
            },
            "base64"
          );
        });

        const response = await fetch(compressedImage);
        const compressedImageBlob = await response.blob();
        const compressedFile = new File([compressedImageBlob], file.name, {
          type: `image/${file.name.split(".")[1]}`,
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
        });

        newImages.push(compressedFile);
        newPreviewImages.push(URL.createObjectURL(compressedFile));
      } catch (error) {
        toast.error("Failed to compress the image.");
        return;
      }
    }
    setImages([...images, ...newImages]);
    setPreviewImages([...previewImages, ...newPreviewImages]);
  };
  const handleDrop = async (droppedFiles) => {
    const newImages = [];
    const newPreviewImages = [];

    const maxSizeInKB = 2048;
    const validFormats = ["image/jpeg", "image/jpg", "image/png"];

    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles[i];
      const fileType = file.type;

      if (!validFormats.includes(fileType)) {
        toast.error(
          `Error: Invalid file format ${file?.name}. Please select a .jpg, .jpeg, or .png image.`
        );

        continue;
      }

      if (file.size > maxSizeInKB * 1024) {
        toast.warn("You can only upload a 2MB picture.");

        continue;
      } else {
        try {
          const compressedImage = await new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              300,
              300,
              "JPEG",
              70,
              0,
              (compressedImage) => {
                resolve(compressedImage);
              },
              "base64"
            );
          });

          const response = await fetch(compressedImage);
          const compressedImageBlob = await response.blob();
          const compressedFile = new File([compressedImageBlob], file.name, {
            type: `image/${file.name.split(".")[1]}`,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
          });

          newImages.push(compressedFile);
          newPreviewImages.push(URL.createObjectURL(compressedFile));
        } catch (error) {
          toast.error("Failed to compress the image.");
          return;
        }
      }
    }

    setImages([...images, ...newImages]);
    setPreviewImages([...previewImages, ...newPreviewImages]);
  };

  const secondTable = (e) => {
    e.preventDefault();
    setSecondTableItems([...secondTableItems, { selectedOptions }]);
    setSelectedOptions("");
  };

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const subItemsClick = (e) => {
    e.preventDefault();
    setItems([...Items, { subItem, price, calories }]);
    setSubItem("");
    setPrice("");
    setCalories("");
  };
  const addCategoryHandle = (event) => {
    event.preventDefault();
    if (!categoryImage) {
      toast.error("please Upload Category Image");
      return;
    }
    window.scrollTo(0, 0);
    dispatch(
      addCategory(
        {
          categoryName: name,
          imageURL: categoryImage,
          menuID,
          restaurantID: user.restaurantID,
          isDeleted: false,
          isVisible: true,
        },
        (categoriesID) => {
          console.log({ categoriesID });
          const obj = {
            type: "category",
            categoryName: name,
            imageURL: categoryImage,
            items: [],
            add: false,
            isVisible: true,
            isDeleted: false,
            categoriesID,
          };
          setCategoriesAndItems((prevCategories) => [obj, ...prevCategories]);
          if (saveAndAddMore) {
            setCategoryImage("");
            setName("");
            setSaveAndAddMore(false);
          } else {
            setCustomOffcanvas(false);
            setCategoryImage("");
            setName("");
            setSaveAndAddMore(false);
          }
        }
      )
    );
  };

  const DuplicateCategory = (e, category) => {
    e.preventDefault();
    console.log({ category });
    let obj = {
      categoryName: category?.categoryName,
      imageURL: category?.imageURL,
      restaurantID: user.restaurantID,
      menuID,
      isDeleted: false,
      isVisible: true,
    };
    let items = category?.items || [];
    dispatch(
      duplicateCategory(obj, items, (categoriesID) => {
        const obj = {
          type: "category",
          categoryName: category?.categoryName,
          imageURL: category?.imageURL,
          items: category?.items,
          openSubMenu: true,
          addItems: false,
          categoriesID,
          isVisible: true,
          isDeleted: false,
        };
        setCategoriesAndItems((prevCategories) => [obj, ...prevCategories]);
      })
    );
  };

  const handleDeleteCategory = (event, element) => {
    event.preventDefault();
    if (element.id) {
      dispatch(
        deleteCategoryNew(element.id, () => {
          const filteredItems = categoriesAndItems.filter((item) => {
            return item?.id !== element.id;
          });
          setCategoriesAndItems(filteredItems);
          setIsActiveMenu(false);
          setSelectedIndex(-1);
        })
      );
    } else {
      dispatch(
        deleteCategoryNew(element.categoriesID, () => {
          const filteredItems = categoriesAndItems.filter((item) => {
            return item?.categoriesID !== element.categoriesID;
          });
          setCategoriesAndItems(filteredItems);
          setIsActiveMenu(false);
          setSelectedIndex(-1);
        })
      );
    }
  };
  const handleDeleteItem = (event, element) => {
    dispatch(
      deleteItem(element.id, () => {
        let category = element.categoriesID;

        const desiredElement = categoriesAndItems.find(
          (cat) => cat?.id == category
        );
        if (desiredElement) {
          const itemsArray = desiredElement.items;
          const updatedItemsArray = itemsArray.filter(
            (item) => item.id !== element.id
          );
          console.log(`Items in category after removal:`, updatedItemsArray);
          setIsActiveMenuItems(!isActiveMenuItems);
          desiredElement.items = updatedItemsArray;
        } else {
          console.log(`Category not found.`);
        }
      })
    );
  };

  const addItemhandle = (event) => {
    let obj = {
      name: item,
      comments,
      estimatedTime,
      sizes: Items,
      label: label || [],
      images: images || [],
      recommendedProducts: secondTableItems,
      restaurantID: user.restaurantID,
      menuID,
      categoriesID: category?.id || categoryData?.categoriesID,
      isVisible: true,
    };
    dispatch(
      addMenu(obj, (res) => {
        const updatedCategoriesAndItems = [...categoriesAndItems];
        if (category) {
          if (category?.categoriesID) {
            const categoryObj = updatedCategoriesAndItems.find(
              (ele) => ele.categoriesID === category.categoriesID
            );
            categoryObj.items = [res, ...categoryObj.items];
            setProductOptions(
              categoryObj?.items?.map((item) => {
                return { value: `${item?.name}`, label: `${item?.name}` };
              })
            );
            categoryObj?.items?.length > 0
              ? setshowRecommended(true)
              : setshowRecommended(false);
          } else {
            const categoryObj = updatedCategoriesAndItems?.find(
              (ele) => ele.id === res.categoriesID
            );
            categoryObj.items = [res, ...categoryObj.items];
            setProductOptions(
              categoryObj?.items?.map((item) => {
                return { value: `${item?.name}`, label: `${item?.name}` };
              })
            );
            categoryObj?.items?.length > 0
              ? setshowRecommended(true)
              : setshowRecommended(false);
          }
        } else {
          updatedCategoriesAndItems.push(res);
        }
        setCategoriesAndItems(updatedCategoriesAndItems);

        clearData();
        setCustomOffcanvas(false);
        setCategory("");
      })
    );
  };

  const DuplicateItem = (e, Item) => {
    e.preventDefault();
    dispatch(
      duplicateItem(Item.id, (res) => {
        console.log({ Item, res });
        const updatedCategoriesAndItems = [...categoriesAndItems];

        const categoryObj = updatedCategoriesAndItems.find(
          (ele) => ele.id === Item.categoriesID
        );
        console.log({ categoryObj });
        categoryObj.items.push({
          type: "item",
          name: Item?.name,
          images: Item?.images,
          sizes: Item?.sizes,
          isVisible: Item?.isVisible,
          categoriesID: Item?.categoriesID,
          id: res,
        });
      })
    );
  };

  const clearData = () => {
    setItem("");
    setComments("");
    setImages("");
    setLabel("");
    setSelectedOptions([]);
    setSecondTableItems([]);
    setItems([]);
    setEstimatedTime("");
    setCategory("");
    setPrice("");
    setPreviewImages([]);
  };

  const setEditCategory = (event, category) => {
    event.preventDefault();
    setCategoryImage(category?.imageURL);
    setName(category?.categoryName);
    setSelectedOption("Categories");
    setCustomOffcanvas(true);
    setToEdit(category);
  };

  const updateCategoryHandle = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    dispatch(
      editCategory(
        toEdit.id,
        name,
        categoryImage,
        categoryImagesToRemove,
        (res) => {
          const tempArr = [...categoriesAndItems];
          const toUpdate = tempArr.find((ele) => ele.id == res.id);
          toUpdate.categoryName = res.name;
          toUpdate.imageURL = res.imageURL;

          if (!saveAndAddMore) {
            setSaveAndAddMore(false);
          }
          setCategoryImage("");
          setName("");
        }
      )
    );
  };

  const setEditItem = (event, item) => {
    event.preventDefault();
    setItem(item?.name);
    setComments(item?.comments);
    setImages(item?.images);
    setPreviewImages(item?.images);
    setLabel(item?.label);
    setItems(item?.sizes);
    setEstimatedTime(item?.estimatedTime);
    setSecondTableItems(item?.recommendedProducts);
    setSelectedOption("Items");
    setCustomOffcanvas(true);
    setToEdit(item);
  };

  const updateItemHandle = (event) => {
    console.log('its update');
    let imageToSend = [];

    for (let i = 0; i < images.length; i++) {
      if (!imagesToRemove.includes(images[i])) {
        imageToSend.push(images[i]);
      }
    }

    let obj = {
      name: item,
      comments,
      estimatedTime,
      sizes: Items || [],
      label: label || [],
      images: imageToSend || [],
      recommendedProducts: secondTableItems || [],
      restaurantID: user.restaurantID,
      menuID,
      categoriesID: toEdit?.categoriesID || "",
    };
console.log(obj,"obj>>>>>>>>>>>>>>>>>>>>>");
    dispatch(
      editMenu(
        toEdit?.id,
        obj,
        (res) => {
          const tempArr = [...categoriesAndItems];
          if (res.categoriesID) {
            const categoryIndex = tempArr.findIndex(
              (ele) => res.categoriesID === ele.id
            );

            if (categoryIndex !== -1) {
              const category = tempArr[categoryIndex];

              const itemIndex = category.items.findIndex(
                (item) => res.id === item.id
              );

              if (itemIndex !== -1) {
                category.items[itemIndex] = res;
              }
            }
          } else {
            const respectedIndex = tempArr.findIndex((ele) => ele.id == res.id);
            tempArr.splice(respectedIndex, 1, res);
          }
          if (!saveAndAddMore) {
            setCustomOffcanvas(false);
          }
          setCategoriesAndItems(tempArr);
          clearData();

          setCategory("");
          setToEdit("");
        },
        imagesToRemove
      )
    );
  };

  useEffect(() => {
    if (editMenuData.length == 0) {
      dispatch(
        getParentMenu(menuID, (res) => {
          setCategoriesAndItems(res || []);
        })
      );
    }
    setCategoriesAndItems(editMenuData || []);
  }, []);

  useEffect(() => {
    dispatch(getLabels(user?.restaurantID));
  }, []);

  const handleEstimatedTimeChange = (event) => {
    const inputVal = event.target.value;

    const digitsOnly = inputVal.replace(/\D/g, "");

    if (digitsOnly.length <= 4) {
      let formattedTime = digitsOnly.slice(0, 2);

      if (digitsOnly.length > 2) {
        formattedTime += " - " + digitsOnly.slice(2, 4);
      }

      setEstimatedTime(formattedTime);
    }
  };

  const handleMouseEnter = (index) => {
    setIsHoverIndex(index);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHoverIndex(-1);
    setIsHovered(false);
  };
  const handleDownload = (e) => {
    e.preventDefault();
    const fileUrl = menuFile;

    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "sample_menu";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container className="mt-5 pt-5 vh-100">
      <Row>
        <Col className=" shadow-sm bg-white pt-3 mx-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <i
              className="fas fa-2x fa-angle-left cursor-pointer"
              onClick={() => {
                history.push("/admin/new-menu");
              }}
            ></i>

            <h2 className="mb-0 ml-3">Edit Menu</h2>
            <Link to="#" onClick={handleDownload}>
              Check Sample File
            </Link>
          </div>
        </Col>
      </Row>
      {editLabelLoader ? (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
          <Spinner size={"lg"} className="text-primary"></Spinner>
        </div>
      ) : (
        <Row className="row-height mt-2">
          {!customOffcanvas && (
            <Col md={3} className="">
              <div className="h-100 w-100 p-3 shadow rounded bg-white">
                <div className="d-flex justify-content-between align-items-center ">
                  <h3>Categories</h3>
                  <i
                    className="fas fa-plus cursor-pointer"
                    onClick={() => {
                      setSelectedOption("Categories");
                      setCustomOffcanvas(true);
                    }}
                  ></i>
                </div>
                {}
                {categoriesAndItems
                  ?.filter((ele) => ele.type == "category" && !ele.isDeleted)
                  .map((ele, index) => {
                    return (
                      <div key={index} className="d-flex align-items-center">
                        {" "}
                        <i className="fas fa-ellipsis-v"></i>
                        <i className="fas fa-ellipsis-v"></i>
                        <h2 key={index} className="ml-3  mt-2 text-uppercase">
                          {ele?.categoryName}
                        </h2>
                      </div>
                    );
                  })}
              </div>
            </Col>
          )}

          <Col className=" pt-4">
            {!categoriesAndItems && (
              <p className="text-center p-3 rounded border-danger">
                You don't have any category yet. Start adding one.
              </p>
            )}
            <div
              className="d-flex align-items-center py-2 w-100  bg-white p-3 rounded  cursor-pointer"
              onClick={function noRefCheck() {
                setSelectedOption("Categories");
                setCategoryImage("");
                setName("");
                setToEdit("");
                setCustomOffcanvas(true);
              }}
            >
              <i className="fas fa-plus cursor-pointer pb-1"></i>
              <h3 className="ml-2 mb-0 ">Add Categories</h3>
            </div>

            {categoriesAndItems?.map((ele, index) => {
              if (ele.type == "category" && !ele.isDeleted) {
                const toggleDropdownItems = (index) => {
                  // create a new array that updates the items property of the clicked category
                  if (index != undefined) {
                    const updatedCategories = [...categoriesAndItems];
                    updatedCategories[index].openSubMenu =
                      !updatedCategories[index].openSubMenu;
                    setCategoriesAndItems(updatedCategories);

                    const elementWithOpenSubMenu = updatedCategories.find(
                      (element) => element.openSubMenu === true
                    );
                    // console.log({ elementWithOpenSubMenu });
                    if (elementWithOpenSubMenu) {
                      setCategoryData(elementWithOpenSubMenu);
                    }
                  }
                };
                // console.log(index, "category");
                return (
                  <>
                    <div
                      key={index}
                      className="mt-2 shadow d-flex justify-content-between align-items-center p-3  shadow-sm rounded  "
                      style={{
                        backgroundColor: index === id ? hoverColor : "white",
                        color: index === id && colorText,
                      }}
                      onMouseEnter={() => {
                        setHoverColor(
                          restaurantMedia.adminColor
                            ? restaurantMedia.adminColor
                            : "#d70365"
                        );
                        setColorText("white");
                        setId(index);
                      }}
                      // Set the hover color on mouse enter
                      onMouseLeave={() => {
                        setHoverColor("white");
                        setColorText("#32325d");
                        setId(index);
                      }}
                    >
                      <div className="d-flex align-items-center ">
                        {/* <i className="fas fa-bars"></i> */}
                        <i className="fas fa-equals"></i>
                        <img
                          src={
                            ele.imageURL
                              ? ele.imageURL instanceof File
                                ? URL.createObjectURL(ele.imageURL)
                                : ele.imageURL
                              : Logo
                          }
                          alt=""
                          height={50}
                          width={50}
                          className="ml-4 rounded"
                        />
                        <h2
                          className="ml-3 mb-0"
                          style={{
                            color: index === id && colorText,
                          }}
                        >
                          {ele?.categoryName?.toUpperCase()}
                        </h2>
                      </div>

                      <div className="d-flex justify-content-center align-items-center">
                        <input
                          type="file"
                          // onChange={(e) => {
                          //   setFile(e.target.files[0]);
                          // }}
                          onChange={handleImport}
                          ref={fileRef}
                          hidden
                        ></input>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() => {
                            setCategory(ele);
                            handleClickMenu();
                          }}
                        >
                          Import Items
                        </Button>
                        <div className="mx-2">
                          <SwitchToggler
                            activeValue={ele?.isVisible ? 1 : 0}
                            collection={"categories"}
                            id={ele.id}
                          />
                        </div>
                        <div
                          className="position-relative z-index-1"
                          ref={CategoriesRef}
                        >
                          <button
                            className="mx-1 border-0"
                            style={{
                              background: "none",
                            }}
                            data-bs-autoClose='true'
                            onClick={() => {
                              setIsActiveMenuItems(false);
                              if (selectedIndex == index) {
                                setIsActiveMenu(false);
                                setSelectedIndex(-1);
                              } else {
                                setIsActiveMenu(true);
                                setSelectedIndex(index);
                              }
                            }}
                          >
                            <i
                              className="fa fa-cog cursor-pointer"
                              aria-hidden="true"
                              style={{
                                color: index === id && colorText,
                              }}
                            ></i>
                          </button>
                          {selectedIndex == index && isActiveMenu && (
                            <div
                              className="position-absolute my-1 bg-white text-dark "
                              style={{
                                width: "140px",
                                paddingTop: "2px",
                                zIndex: "12",
                                border: "2px solid lightgray",
                                borderRadius: "10px",
                                marginLeft: "-50px",
                              }}
                            >
                              <div className="p-2 ">
                                <h5
                                  className="set-hover p-2 mb-0 cursor-pointer"
                                  value="value 1"
                                  onClick={(event) => {
                                    setEditCategory(event, ele);
                                    setIsActiveMenu(false);
                                  }}
                                >
                                  Edit
                                </h5>
                                <hr className="breaker" />

                                <h5
                                  className="set-hover p-2 mb-0 cursor-pointer"
                                  value="value 1"
                                  onClick={(event) => {
                                    DuplicateCategory(event, ele);
                                    setIsActiveMenu(false);
                                  }}
                                >
                                  Duplicate
                                </h5>
                                <hr className="breaker" />

                                <h5
                                  className="set-hover p-2 mb-0 cursor-pointer"
                                  value="value 2"
                                  onClick={(event) => {
                                    handleDeleteCategory(event, ele);
                                  }}
                                >
                                  Remove
                                </h5>
                              </div>
                            </div>
                          )}
                        </div>
                        <i
                          className={`fas fa-chevron-up mr-3 ml-2 cursor-pointer ${
                            ele.openSubMenu ? "rotate" : "rotate-back"
                          }`}
                          onClick={(event) => {
                            event.stopPropagation();
                            toggleDropdownItems(index);
                          }}
                        ></i>
                      </div>
                    </div>
                    {ele.openSubMenu && (
                      <Col>
                        {ele.items?.map((items, index) => {
                          if (!items.isDeleted) {
                            // console.log({ ele }, "menus");
                            return (
                              <div
                                key={index}
                                className="mt-2 shadow d-flex justify-content-between align-items-center  p-3 w-100  float-right shadow-sm rounded  cursor-pointer"
                                onMouseEnter={() => {
                                  setHoverColorMenu(
                                    restaurantMedia.adminColor
                                      ? restaurantMedia.adminColor
                                      : "#d70365"
                                  );
                                  setTextColorMenu("white");
                                  setId(items.id);
                                }}
                                onMouseLeave={() => {
                                  setHoverColorMenu("white");
                                  setTextColorMenu("#32325d");
                                  setId(items.id);
                                }}
                                // Set the initial hover color when the mouse leaves
                                style={{
                                  backgroundColor:
                                    items.id === id ? hoverColorMenu : "white",
                                  color: items.id === id && textColorMenu,
                                }}
                                // onClick={(event) => {
                                //   setEditItem(event, ele);
                                // }}
                              >
                                {/* <div className="d-flex align-items-center justify-content-between"> */}
                                {/* <i className="fas fa-bars"></i> */}
                                <div className="d-flex align-items-center">
                                  <i className="fas fa-equals"></i>
                                  <img
                                    src={
                                      items?.images.length > 0
                                        ? items?.images[0] instanceof File
                                          ? URL.createObjectURL(
                                              items?.images[0]
                                            )
                                          : items?.images[0]
                                        : Logo
                                    }
                                    height={50}
                                    width={50}
                                    className="ml-4 rounded"
                                  />
                                  <h2
                                    className="ml-3 mb-0"
                                    style={{
                                      color:
                                        items.id === id
                                          ? textColorMenu
                                          : "#32325d",
                                    }}
                                  >
                                    {items.name?.toUpperCase()}
                                  </h2>
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="mx-2">
                                    <SwitchToggler
                                      activeValue={items?.isVisible ? 1 : 0}
                                      collection={"menus"}
                                      id={items.id}
                                    />
                                  </div>
                                  <div
                                    className="position-relative z-index-1"
                                    ref={itemsRef}
                                  >
                                    <button
                                      className="mx-1 border-0"
                                      style={{
                                        background: "none",
                                      }}
                                      data-bs-autoClose='true'
                                      onClick={() => {
                                        setIsActiveMenu(false);
                                        if (selectedItemIndex == items.id) {
                                          setSelectedItemIndex("");
                                          setIsActiveMenuItems(false);
                                        } else {
                                          setIsActiveMenuItems(true);
                                          setSelectedItemIndex(items.id);
                                        }
                                      }}
                                    >
                                      <i
                                        className="fa fa-cog cursor-pointer "
                                        aria-hidden="true"
                                        style={{
                                          color:
                                            items.id === id && textColorMenu,
                                        }}
                                      ></i>
                                    </button>
                                    {selectedItemIndex == items.id &&
                                      isActiveMenuItems && (
                                        <div
                                          className="position-absolute my-1 bg-white text-dark "
                                          style={{
                                            width: "140px",
                                            paddingTop: "2px",
                                            zIndex: "12",
                                            border: "2px solid lightgray",
                                            borderRadius: "10px",
                                            marginLeft: "-70px",
                                          }}
                                        >
                                          <div className="p-2 ">
                                            <h5
                                              className="set-hover p-2 mb-0 cursor-pointer"
                                              value="value 1"
                                              onClick={(event) => {
                                                setEditItem(event, items);
                                                setIsActiveMenuItems(
                                                  !isActiveMenuItems
                                                );
                                              }}
                                            >
                                              Edit
                                            </h5>
                                            <hr className="breaker" />
                                            <h5
                                              className="set-hover p-2 mb-0 cursor-pointer"
                                              value="value 1"
                                              onClick={(event) => {
                                                DuplicateItem(event, items);
                                                setIsActiveMenuItems(
                                                  !isActiveMenuItems
                                                );
                                              }}
                                            >
                                              Duplicate
                                            </h5>
                                            <hr className="breaker" />
                                            <h5
                                              className="set-hover p-2 mb-0 cursor-pointer"
                                              value="value 2"
                                              onClick={(event) => {
                                                handleDeleteItem(event, items);
                                              }}
                                            >
                                              Remove
                                            </h5>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                              // </div>
                            );
                          }
                        })}
                        <div
                          className="d-flex align-items-center p-3 mt-2  rounded w-100 mb-2 cursor-pointer float-right mr-2"
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                          style={{
                            background:
                              isHovered && index == isHoverIndex
                                ? restaurantMedia.adminColor
                                  ? restaurantMedia.adminColor
                                  : "#d70365"
                                : "#ffffff",
                            color:
                              isHovered && index == isHoverIndex ? "white" : "",
                          }}
                          onClick={() => {
                            setSelectedOption("Items");
                            clearData();
                            setToEdit("");
                            setCustomOffcanvas(true);
                            setCategory(ele);
                          }}
                        >
                          <i className="fas fa-plus cursor-pointer pb-1"></i>
                          <h3
                            className="ml-2 mb-0 "
                            style={{
                              color:
                                isHovered && index == isHoverIndex
                                  ? "#ffffff"
                                  : "#32325d",
                            }}
                          >
                            {toEdit ? "Edit Item" : "Add Item"}
                          </h3>
                        </div>
                      </Col>
                    )}
                  </>
                );
              } else {
                return;
              }
            })}
          </Col>
          {customOffcanvas &&
            (selectedOption == "Categories" ? (
              //Add Categories
              <Col md={6}>
                <div className="w-100 h-100 bg-white shadow-sm d-flex flex-column justify-content-evenly  side-offcanvas">
                  {/* Header */}
                  <div className="w-100 d-flex align-items-center py-4 px-4 border-bottom border-top">
                    {" "}
                    <i
                      className="fas fa-close fa-2x cursor-pointer"
                      onClick={() => {
                        setCategoryImage("");
                        setName("");
                        setToEdit("");
                        setSaveAndAddMore(false);
                        setCustomOffcanvas(false);
                      }}
                    ></i>
                    <h2 className="mb-0 ml-4">
                      {toEdit ? "Edit Category" : "Add New Category"}
                    </h2>
                  </div>
                  {/* body */}
                  <div>
                    <Form
                      onSubmit={(event) => {
                        toEdit
                          ? updateCategoryHandle(event)
                          : addCategoryHandle(event);
                      }}
                    >
                      <div className="px-3 py-2">
                        <FormGroup>
                          <Label for="name">
                            <strong className="text-danger">*</strong>Name
                          </Label>
                          <Input
                            id="name"
                            name="name"
                            placeholder="Pasta"
                            type="text"
                            required
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </FormGroup>
                        {/* {image section for categories} */}
                        <div>
                          <div
                            onDragOver={(e) => {
                              e.preventDefault();
                            }}
                            onDragLeave={(e) => {
                              e.preventDefault();
                            }}
                            onDrop={(e) => {
                              e.preventDefault();
                              handleDropCategory(e.dataTransfer.files[0]);
                            }}
                          >
                            {categoryImage ? (
                              <div className="d-flex flex-column justify-content-center align-items-center border">
                                <img
                                  src={
                                    categoryImage instanceof File
                                      ? URL.createObjectURL(categoryImage)
                                      : categoryImage
                                  }
                                  alt="Uploaded"
                                  style={{
                                    width: "200px",
                                    height: "200px",
                                    border: "1px dotted #dde2e7",
                                  }}
                                  className="cursor-pointer rounded m-1"
                                  onClick={handleImageClick}
                                />
                                <i
                                  className="fa-regular fa-pen-to-square w-100  py-2 shadow-sm cursor-pointer d-flex justify-content-center border"
                                  style={{
                                    backgroundColor: hoverEdit,
                                    color: colorIconEdit,
                                  }}
                                  onMouseEnter={() => {
                                    setHoverEdit(
                                      restaurantMedia.adminColor
                                        ? restaurantMedia.adminColor
                                        : "#d70365"
                                    );
                                    setColorIconEdit("white");
                                  }}
                                  // Set the hover color on mouse enter
                                  onMouseLeave={() => {
                                    setHoverEdit("white");
                                    setColorIconEdit("#32325d");
                                  }}
                                  onClick={handleImageClick}
                                ></i>
                              </div>
                            ) : (
                              <div
                                className={`placeholder-image ${
                                  droppedFile ? "dropped" : ""
                                } cursor-pointer`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  border: "1px dotted #dde2e7",
                                  padding: "10px",
                                  margin: "0 auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "12px",
                                }}
                                onClick={handleImageClick}
                              >
                                {droppedFile ? (
                                  <img
                                    src={URL.createObjectURL(droppedFile)}
                                    alt="Dropped"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={fileupload}
                                    alt="Placeholder"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </div>
                            )}
                            <input
                              type="file"
                              ref={fileInput}
                              accept=".jpg,.jpeg,.png"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </div>
                        </div>
                        <p className="text-muted mt-3">
                          Recommended resolution is for landscape 1536x1024px,
                          square 1536x1536px or portrait 1536x2304 or bigger
                          with a file size of less than 10MB.
                        </p>
                      </div>
                      <div
                        className={`d-flex  align-items-center py-2  px-3 border-bottom border-top      ${
                          toEdit
                            ? "justify-content-end"
                            : "justify-content-between"
                        }`}
                      >
                        {!toEdit && (
                          <FormGroup check>
                            <Input
                              type="checkbox"
                              checked={saveAndAddMore}
                              onChange={() => {
                                setSaveAndAddMore(!saveAndAddMore);
                              }}
                              style={{ width: "1em", height: "1em" }}
                            />
                            <Label check>Save and add more</Label>
                          </FormGroup>
                        )}
                        <Button
                          type="submit"
                          className="d-flex align-items-center bg-primary text-white"
                          disabled={categoryLoader}
                        >
                          {categoryLoader ? (
                            <Spinner size={"sm"} className="mr-3"></Spinner>
                          ) : (
                            ""
                          )}
                          {toEdit ? "Update" : "Submit"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            ) : (
              // Add items
              <Col md={6}>
                <div className="w-100 h-100 bg-white shadow-sm d-flex flex-column justify-content-evenly ">
                  {/* Header */}
                  <div className="d-flex align-items-center py-4 px-4 border-bottom border-top">
                    {" "}
                    <i
                      className="fas fa-close fa-2x cursor-pointer"
                      onClick={() => {
                        setCustomOffcanvas(false);
                        setToEdit("");
                      }}
                    ></i>
                    <h2 className="mb-0 ml-4">
                      {toEdit ? "Edit Items" : "Add Items"}
                    </h2>
                  </div>
                  {/* body */}
                  <div className="p-4 ">
                    <Form
                      // onSubmit={(event) => {
                      //   toEdit ? updateItemHandle(event) : addItemhandle(event);
                      // }}
                      onSubmit={(event) => {
                        event.preventDefault();
                        if (Items?.length < 1) {
                          toast.error("Please add some sizes to the menu");
                        } else {
                          toEdit
                            ? updateItemHandle(event)
                            : addItemhandle(event);
                        }
                      }}
                    >
                      <Row>
                        <Col>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label for="item-name">Item Name</Label>
                                <Input
                                  type="text"
                                  name="item"
                                  id="item"
                                  required
                                  placeholder="Item Name"
                                  value={item}
                                  onChange={(e) => setItem(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label for="item-name">
                                  Est. Prep Time(min)
                                </Label>
                                <Input
                                  type="text" // Use 'text' type to allow the pattern change
                                  placeholder="00 - 00"
                                  value={estimatedTime}
                                  onChange={handleEstimatedTimeChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <div>
                            <Label for="price">Comments</Label>
                            <RichEditor
                              comments={comments}
                              setComments={setComments}
                            />
                          </div>

                          <Row className="mt-3">
                            <Col>
                              {" "}
                              <FormGroup>
                                <Label for="label">Label</Label>

                                <Select
                                  options={labelsData?.map((item) => {
                                    return {
                                      value: item?.labelName,
                                      label: item?.labelName,
                                      id: item?.id,
                                    };
                                  })}
                                  isMulti
                                  value={label}
                                  defaultValue={null}
                                  name="label"
                                  id="label"
                                  placeholder="Select Label"
                                  isSearchable={true}
                                  onChange={(selected) =>
                                    setLabel([...selected])
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="item-name">Add Label</Label>
                                <div>
                                  <Button
                                    size="sm"
                                    color="primary"
                                    className="px-3 py-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      addtoggle();
                                    }}
                                  >
                                    Add Label
                                  </Button>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col>

                          <Row>
                            {previewImages?.map((url, index) => {
                              return (
                                <Col md={3} className="mt-2">
                                  <div
                                    key={index}
                                    className="d-flex flex-column align-items-center h-100 w-100 justify-content-between border mt-1"
                                  >
                                    <img
                                      width="100%"
                                      // height="auto"
                                      src={url}
                                      alt=""
                                      className="p-1 menu_images"
                                    />
                                    <i
                                      className="fas fa-trash-alt p-2  border w-100 d-flex justify-content-center  cursor-pointer shadow-sm "
                                      style={{
                                        backgroundColor:
                                          index === id ? hoverDelete : "white",
                                        color: index === id && colorIcon,
                                      }}
                                      onMouseEnter={() => {
                                        setHoverDelete(
                                          restaurantMedia.adminColor
                                            ? restaurantMedia.adminColor
                                            : "#d70365"
                                        );
                                        setColorIcon("white");
                                        setId(index);
                                      }}
                                      // Set the hover color on mouse enter
                                      onMouseLeave={() => {
                                        setHoverDelete("white");
                                        setColorIcon("#32325d");
                                      }}
                                      onClick={() => {
                                        setImagesToRemove(
                                          (prevImagesToRemove) => [
                                            ...prevImagesToRemove,
                                            url,
                                          ]
                                        );
                                        setPreviewImages((prevPreviewImages) =>
                                          prevPreviewImages.filter(
                                            (image) => image !== url
                                          )
                                        );
                                      }}
                                    ></i>
                                  </div>
                                </Col>
                              );
                            })}
                            <Col
                              md={3}
                              className="mt-2"
                              onDragOver={(e) => {
                                e.preventDefault();
                                setIsDragOver(true);
                              }}
                              onDragLeave={(e) => {
                                e.preventDefault();
                                setIsDragOver(false);
                              }}
                              onDrop={(e) => {
                                e.preventDefault();
                                setIsDragOver(false);
                                handleDrop(e.dataTransfer.files);
                              }}
                            >
                              <FormGroup className="file-input mt-2">
                                <input
                                  type="file"
                                  multiple
                                  name="item"
                                  id="file-images"
                                  placeholder="Images"
                                  className="file-input__input"
                                  accept=".jpg,.jpeg,.png"
                                  onChange={imagesHandleChange}
                                />
                                <Label
                                  for="file-images"
                                  className={`d-flex flex-column file-input__label ${
                                    previewImages && previewImages.length > 0
                                      ? "d-none"
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={fileupload}
                                    alt=""
                                    className="mb-2"
                                  />
                                  <span className="text-center">
                                    Upload Images
                                  </span>
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <hr className="my-1" />

                      <Row className="mt-3 d-flex justify-content-center align-items-center">
                        <Col
                          className="col-9"
                          // md={9} lg={9} xl={9} sm={9} xs={9}
                        >
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label for="item-name">Size</Label>
                                <Input
                                  type="text"
                                  name="item"
                                  id="item"
                                  placeholder="Item Size"
                                  value={subItem}
                                  onChange={(e) => setSubItem(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="item-name">Price</Label>
                                <Input
                                  type="number"
                                  min={0}
                                  step="any"
                                  name="item"
                                  id="item"
                                  placeholder="Price"
                                  value={price}
                                  onBlur={(e) => {
                                    if (Number(e.target.value) < 0) {
                                      setPrice("");
                                    }
                                  }}
                                  onChange={(e) => setPrice(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="item-name">Calories</Label>
                                <Input
                                  type="Number"
                                  min={1}
                                  name="item"
                                  id="item"
                                  // required
                                  placeholder="Calories"
                                  value={calories}
                                  onBlur={(e) => {
                                    if (Number(e.target.value) < 0) {
                                      setCalories("");
                                    }
                                  }}
                                  onChange={(e) => setCalories(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          // md={5}
                          // lg={5}
                          // xl={5}
                          // sm={3}
                          // xs={3}
                          className="mt-2 col-3"
                        >
                          <Button
                            className="modal__btn"
                            disabled={!subItem || !price || !calories}
                            onClick={(e) => subItemsClick(e)}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>

                      <Table
                        className="align-items-center table-flush sub__items-table"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Size</th>
                            <th scope="col">Price</th>
                            <th scope="col">Calories</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Items?.map((item, index) => {
                            return (
                              <tr key={index}>
                                {editingIndex === index ? (
                                  <>
                                    <td>
                                      <Input
                                        type="text"
                                        placeholder="Item Name"
                                        value={item.subItem}
                                        onChange={(e) =>
                                          handleItemChange(e, index, "subItem")
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        type="number"
                                        placeholder="Price"
                                        min={0}
                                        value={item.price}
                                        onChange={(e) =>
                                          handleItemChange(e, index, "price")
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        type="number"
                                        placeholder="calories"
                                        min={0}
                                        value={item.calories}
                                        onChange={(e) =>
                                          handleItemChange(e, index, "calories")
                                        }
                                      />
                                    </td>
                                    <td className="table-actions">
                                      <Button
                                        size="sm"
                                        color="primary"
                                        onClick={() =>
                                          handleUpdateClick(index, item)
                                        }
                                      >
                                        Update
                                      </Button>{" "}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <td className="text-capitalize">
                                      {item.subItem}
                                    </td>
                                    <td>{item.price}</td>
                                    <td>{item.calories}</td>
                                    <td className="table-actions">
                                      {/* <img src={hide} alt="" className="mx-2" /> */}
                                      <img
                                        src={edit}
                                        alt=""
                                        className="mx-2"
                                        onClick={() => setEditingIndex(index)}
                                      />
                                      <img
                                        src={deleteIcon}
                                        alt=""
                                        className="mx-2"
                                        onClick={() =>
                                          handleRemove("subItems", index)
                                        }
                                      />
                                    </td>
                                  </>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      <hr className="mt-1 mb-2" />
                      {showRecommended && (
                        <>
                          <Row>
                            <Col>
                              <Row className="d-flex justify-content-end align-items-center">
                                <Col md={8}>
                                  <FormGroup>
                                    <Label for="location">
                                      Recommended Products
                                    </Label>
                                    <Select
                                      isMulti
                                      options={productOptions}
                                      value={selectedOptions}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col
                                  md={4}
                                  className="d-flex justify-content-end align-items-center"
                                >
                                  <Button
                                    className="modal__btn"
                                    disabled={selectedOptions.length == 0}
                                    onClick={(e) => secondTable(e)}
                                  >
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Table
                            className="align-items-center table-flush sub__items-table"
                            responsive
                          >
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Recommend Products</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {secondTableItems?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    {recommendedIndex == index ? (
                                      <>
                                        <td>
                                          <Select
                                            isMulti
                                            value={item.selectedOptions}
                                            options={productOptions}
                                            onChange={(selectedValues) =>
                                              handleRecommededChange(
                                                index,
                                                "selectedOptions",
                                                selectedValues
                                              )
                                            }
                                          />
                                        </td>
                                        <td className="table-actions">
                                          <Button
                                            size="sm"
                                            color="primary"
                                            onClick={() =>
                                              handleRecommededUpdate(
                                                index,
                                                item
                                              )
                                            }
                                          >
                                            Update
                                          </Button>
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <td>
                                          {item?.selectedOptions?.map(
                                            (option, idx) => {
                                              return (
                                                <span className="text-capitalize">
                                                  {option.label}
                                                  {idx !==
                                                  item.selectedOptions.length -
                                                    1
                                                    ? ", "
                                                    : ""}
                                                </span>
                                              );
                                            }
                                          )}
                                        </td>
                                        <td className="table-actions">
                                          <img
                                            src={edit}
                                            alt=""
                                            className="mx-2"
                                            onClick={() =>
                                              setRecommendedIndex(index)
                                            }
                                          />
                                          <img
                                            src={deleteIcon}
                                            alt=""
                                            className="mx-2"
                                            onClick={() =>
                                              handleRemove("recommended", index)
                                            }
                                          />
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      )}

                      <Row>
                        <Col className="d-flex justify-content-end">
                          <Button
                            className="modal__btn"
                            type="submit"
                            disabled={addMenuLoader}
                          >
                            {addMenuLoader ? (
                              <Spinner size={"sm"} className="mr-3"></Spinner>
                            ) : (
                              ""
                            )}{" "}
                            {toEdit ? "Update" : "Save"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      )}

      <div>
        <Modal isOpen={addModal} toggle={addtoggle}>
          <ModalHeader toggle={addtoggle}>Add Label Details</ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(
                  addlabel(
                    {
                      labelName,
                      labelDescription,
                      labelURL: image?.raw,
                      restaurantID: user?.restaurantID,
                      isDeleted: false,
                    },
                    () => {
                      setLabelDescription("");
                      setlabelName("");
                      setImage({ preview: "", raw: "" });
                      setAddModal(false);
                    }
                  )
                );
              }}
            >
              <FormGroup>
                <Label for="name">Label Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  required
                  placeholder="Enter name"
                  value={labelName}
                  onChange={(e) => setlabelName(e.target.value)}
                />
              </FormGroup>
              <div>
                <label htmlFor="upload-button">
                  {image.preview ? (
                    <div className="d-flex flex-column justify-content-start align-items-start mb-2">
                      <Label className="mb-2">Uploaded Label</Label>
                      <div className="upload_label">
                        <img
                          src={image.preview}
                          alt="dummy"
                          width="50"
                          height="50"
                        />
                        <i
                          className="fa-solid fa-x"
                          onClick={() =>
                            setImage({
                              preview: "",
                              raw: "",
                            })
                          }
                        ></i>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-column justify-content-start align-items-start">
                      {" "}
                      <Label>Upload Label</Label>
                      <span className="fa-stack fa-2x mt-3 mb-2">
                        <i className="fas fa-circle fa-stack-2x" />
                        <i className="fas fa-store fa-stack-1x fa-inverse" />
                      </span>
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleChangeImage}
                />
                <br />
              </div>
              <FormGroup>
                <Label for="location">Description</Label>
                <Input
                  type="textarea"
                  placeholder="Enter Description"
                  required
                  value={labelDescription}
                  onChange={(e) => setLabelDescription(e.target.value)}
                />
              </FormGroup>

              <div className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  {addLabelLoader ? <Spinner size="sm" /> : "Save"}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </Container>
  );
};
export default EditScratchMenu;
