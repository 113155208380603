import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import EditScratchMenu from "components/CreateNewMenu/EditScratchMenu";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import routes from "routes.js";
import { getRestaurantImages } from "store/actions/restaurantAction";
import CheckoutResponses from "views/CheckoutResponses";
import NextLogo from "../../src/assets/img/Mpink.png";

const Admin = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { restaurantMedia } = useSelector((state) => state.restaurant);
  const [settingsModal, setSettingsModal] = useState(false);
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [hide, setHide] = useState(false);
  const [loader, setLoader] = useState(true);

  const languagesArray = restaurantMedia?.languages
    ? restaurantMedia?.languages
    : ["en"];
  const languagesString = languagesArray.join(",");
  let includedLanguages = languagesString;
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        includedLanguages: includedLanguages,
        autoDisplay: true,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    if (restaurantMedia?.languages) {
      var googleTranslator = document.getElementById(
        "google-translator-script"
      );
      if (!googleTranslator) {
        var addScript = document.createElement("script");

        addScript.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );

        addScript.setAttribute("id", "google-translator-script");
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
      }
    }
  }, [restaurantMedia?.languages]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop?.layout === "/admin") {
        return (
          <Route
            path={prop?.layout + prop?.path}
            component={prop?.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props?.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const isFirstTimeLogin = () => {
    return localStorage.getItem("firstTimeLogin") !== "true";
  };

  useEffect(() => {
    if (user) {
      dispatch(getRestaurantImages(user?.restaurantID));
    }
  }, [user]);

  useEffect(() => {
    const hasModalBeenShown = localStorage.getItem("settingsModalShown");
    console.log(hasModalBeenShown);
    if (user?.firsttimelogin&&!hasModalBeenShown) {
      setSettingsModal(true);
      localStorage.setItem("settingsModalShown", true);
  }else{
    setSettingsModal(false);
  }
    if ( user && user?.type == "restaurant") {
      const hasPaypalKeys = user?.paypalSecretKey && user?.paypalClientKey;
      const hasStripeKeys = user?.stripeSecretKey && user?.stripeClientKey;
    }
  }, [user]);

  let logo = restaurantMedia?.logo || NextLogo;

  useEffect(() => {
    const isRestaurantMediaNotEmpty = Object.keys(restaurantMedia).length > 0;
    if (isRestaurantMediaNotEmpty) {
      setLoader(false);
    }
  }, [restaurantMedia]);
  return (
    <>
      {!loader ? (
        <>
          <Sidebar
            {...props}
            routes={routes}
            logo={{
              innerLink: "/admin/index",
              imgSrc: logo,
              imgAlt: "...",
            }}
            hide={hide}
            setHide={setHide}
          />
          <div className="main-content" ref={mainContent}>
            <AdminNavbar
              {...props}
              brandText={getBrandText(props?.location.pathname)}
              hide={hide}
              setHide={setHide}
            />
            <Switch>
              <Route
                path="/admin/checkout-responses/:id"
                component={CheckoutResponses}
              />
              <Route path="/admin/edit-menu/:id" component={EditScratchMenu} />
              {getRoutes(routes)}
              {user?.type==='kitchen-cook'? <Redirect from="*" to="/admin/order" />:<Redirect from="*" to="/admin/index" />}
            </Switch>
          </div>
          <Modal isOpen={settingsModal}>
            <ModalHeader>Restaurant Settings</ModalHeader>
            <ModalBody>
              Welcome to the next chapter of your culinary journey! It's time to
              set up your restaurant and unleash its full potential. Seamlessly
              receive orders, securely process payments, and connect with your
              customers in meaningful ways. Your restaurant, your rules. Let's
              turn your vision into reality. Set up your restaurant now and
              embark on a delicious adventure of growth and success!
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                size="sm"
                onClick={() => setSettingsModal(false)}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  history.push("/admin/settings");
                  setSettingsModal(false);
                }}
              >
                Let's Go
              </Button>
            </ModalFooter>
          </Modal>
        </>
      ) : (
        <div className="loader_div">
          <Spinner color="primary" />
        </div>
      )}
    </>
  );
};

export default Admin;
