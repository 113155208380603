import React from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  Button,
  Container,
} from "reactstrap";
import { Subscription } from "views/Subscription";

const Billing = () => {
  const history = useHistory();

  return (
      <Container fluid className="mt-8">
        <div className="col">
          <Card className="shadow">
            <CardHeader className="d-lg-flex  d-sm-block justify-content-between">
              <div className="d-flex align-items-center">
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => {
                    history.push("/admin/settings");
                  }}
                >
                  <i className="fas fa-arrow-left "></i>
                </Button>

                <h3 className=" pt-2 ">Billing</h3>
              </div>
            </CardHeader>
            <div className="mx-3 mx-md-5 my-2 ">
              <Subscription />
            </div>
          </Card>
        </div>
      </Container>
  );
};

export default Billing;
