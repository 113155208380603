/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Col,
  Spinner,
} from "reactstrap";
import moment from "moment";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPlatFormFeedbacks } from "store/actions/restaurantAction";

const PlatformFeedback = () => {
  const dispatch = useDispatch();
  const [loadCount, setLoadCount] = useState(3);
  const { feedbackLoader, platform_feedbacks } = useSelector(
    (state) => state.restaurant
  );

  const handleLoadMore = () => {
    const updatedCount = loadCount + 3;
    setLoadCount(updatedCount);
    dispatch(getPlatFormFeedbacks(updatedCount));
  };
  useEffect(() => {
    dispatch(getPlatFormFeedbacks(loadCount));
  }, [loadCount]);

  // Generate page number components

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="d-lg-flex  d-sm-block justify-content-between">
                <h3 className="mb-0 pt-2 col-lg-3 ">Platform Feedbacks</h3>
              </CardHeader>

              <Container>
                {feedbackLoader && (
                  <div className="d-flex justify-content-center align-items-center py-5">
                    {" "}
                    <Spinner size="md" color="primary" />
                  </div>
                )}
                <Row className="my-5">
                  {platform_feedbacks?.map((feedback, index) => (
                    <Feedback key={index} {...feedback} />
                  ))}
                </Row>
                <Row className="justify-content-end align-items-center my-2 pr-3">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={handleLoadMore}
                    disabled={platform_feedbacks?.length !== loadCount}
                  >
                    {feedbackLoader ? <Spinner size="sm" /> : "Load more"}
                  </Button>
                </Row>
              </Container>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PlatformFeedback;
const Feedback = ({ name, createdAt, rating, comment, serviceExperience }) => (
  <Col sm="6" lg="12">
    <div className="feedback">
      <div className="name-date">
        <span className="name">{name}</span>
        <small className="date">
          {createdAt && moment.unix(createdAt.seconds).format("DD/MM/YYYY ")}
        </small>
      </div>
      <div className="rating">
        {Array.from({ length: rating }, (_, index) => (
          <i key={index} className="fas fa-star text-warning"></i>
        ))}
      </div>
      <div className="emoji">
        {serviceExperience === "happy"
          ? "🙂"
          : serviceExperience === "unhappy"
          ? "😕"
          : serviceExperience === "neutral"
          ? "😐"
          : ""}
      </div>
      <p className="review">{comment}</p>
    </div>
  </Col>
);
