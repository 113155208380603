const initState = {
  authError: "",
  uid: "",
  requested: false,
  registered: false,
  user: null,
  loading: false,
  userPermissions: {},
  forgetLoading: false,
};
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        uid: action.user?.id,
        user: { ...action.user },
        authError: "",
      };
    case "PERMISSIONS":
      return {
        ...state,
        userPermissions: action.payload,
      };
    case "LOGIN_FAIL":
      localStorage.clear();
      return {
        ...state,
        uid: "",
        authError: action.error.message,
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        authError: "",
        uid: "",
        registered: false,
        user: null,
      };
    case "LOGIN_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN_REQUEST_END":
      return {
        ...state,
        loading: false,
      };
    case "FORGET_LOADER":
      return {
        ...state,
        forgetLoading: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user: {
          ...state.user,
          name: action.payload.name,
          email: action.payload.email,
          description: action.payload.description,
          location: action.payload.location,
        },
      };
    case "UPDATE_PAYMENT_METHOD_DETAILS_SUCCESS_PAYPAL":
      console.log(
        action.payload,
        "UPDATE_PAYMENT_METHOD_DETAILS_SUCCESS_PAYPAL>>>>>"
      );
      return {
        ...state,
        user: {
          ...state.user,
          paypalSecretKey: action.payload.paypalSecretKey,
          paypalClientKey: action.payload.paypalClientKey,
        },
      };
    case "UPDATE_PAYMENT_METHOD_DETAILS_SUCCESS_STRIPE":
      return {
        ...state,
        user: {
          ...state.user,
          stripeSecretKey: action.payload.stripeSecretKey,
          stripeClientKey: action.payload.stripeClientKey,
        },
      };
    case "UPDATE_SOCIAL_PROFILE_LINKS":
      return {
        ...state,
        user: {
          ...state.user,
          socialProfiles: state.user.socialProfiles.map((profile) =>
            profile.type === action.payload.type
              ? { ...profile, url: action.payload.url }
              : profile
          ),
        },
      };
    case "UPDATE_RESTAURANT_LANGUAGE":
      return {
        ...state,
        user: {
          ...state.user,
          languages: action.payload.languages,
        },
      };
    case "UPDATE_RESTAURANT_TIP":
      return {
        ...state,
        user: {
          ...state.user,
          percentages: action.payload.percentages,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
export default authReducer;
