import ReactApexChart from "react-apexcharts";

const PieChart = ({ chartOptions, chartSeries, type }) => {
  return (
    <>
      {chartSeries && (
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type={type}
        />
      )}
    </>
  );
};

export default PieChart;
