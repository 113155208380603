import firebase from '../../config/firebase';
export const getTutorials = (entries) => async dispatch => {
	console.log(entries,"entries>>>>>>>>>>>");
	dispatch(tutorialsLoader(true));
	let query = firebase.firestore().collection('tutorials');

    // Check if 'entries' is provided and is a valid number
    if (entries!==undefined) {
        // Apply the limit to the query
        query = query?.limit(entries);
    }
query.onSnapshot(query => {
			let temp = [];
			query.docs.forEach(doc => {
				temp.push({
					id: doc.id,
					...doc.data(),
				});
			});
			dispatch({ type: 'GET_TUTORIALS', payload: temp.reverse() });
			dispatch(tutorialsLoader(false));
		});
};
const tutorialsLoader = data => async dispatch => {
	dispatch({
		type: 'TUTORIALS_LOADER',
		payload: data,
	});
};