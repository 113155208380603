import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  FormGroup,
  Input,
  Spinner,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import searchImg from "../assets/img/icons/search.svg";
// import RangeSlider from "../components/slider/RangeSlider";
import PickDateRange from "./auth/PickDateRange";
import { addDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "store/actions/RequestActions";
import { updateOrder } from "store/actions/RequestActions";
import { orderPaymentRefund } from "store/actions/orderActions";

import moment from "moment";
import { toast } from "react-toastify";
import { getRestaurantCurrency } from "store/actions/orderActions";
import OrderRangeSlider from "components/slider/OrderRangeSlider";
import PrintBill from "components/PrintBill";

const OrdersResturant = () => {
  const dispatch = useDispatch();
  const { user, userPermissions } = useSelector((state) => state.auth);
  const {
    restaurantOrders,
    orderLoader,
    restaurantOrdersRestData,
    restaurantCurrency,
  } = useSelector((state) => state.requests);
  console.log("restaurantorders",restaurantOrders);

  const [activeFilter, setActiveFilter] = useState(0);
  const [filterValue, setFilterValue] = useState("All");
  const [value, setValue] = useState([0, 10000]);
  const [currentPage, setCurrentPage] = useState(0);
  const [modal, setModal] = useState(false);
  const [refundItem, setRefundItem] = useState({});

  const [datestate, setDateState] = useState([
    {
      startDate: addDays(new Date(), -49),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  // console.log("datestate", datestate);
  const [search, setSearch] = useState("");
  const filters = ["All", "New", "processing", "ready", "completed"];
  const startWeek = moment().unix();
  const endWeek = moment().subtract(7, "days").unix();

  const startDate = moment(datestate[0].startDate).unix();
  const endDate = moment(datestate[0].endDate).unix();

  const orderPermissions = userPermissions?.order;
  const handleClick = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    dispatch(
      getAllOrders(
        search,
        user?.restaurantID,
        currentPage,
        filterValue,
        startWeek,
        endWeek,
        startDate,
        endDate,
        value[0],
        value[1]
      )
    );
  }, [filterValue, currentPage, search, startDate, endDate, value]);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = restaurantOrdersRestData?.nbPages;

    if (totalPages >= 1) {
      const visiblePages = 3; // or 4, depending on your preference
      let startPage = Math.max(currentPage - 1, 0);
      let endPage = Math.min(startPage + visiblePages - 1, totalPages - 1);

      if (startPage > 0) {
        pageNumbers.push(
          <PaginationItem key="start-ellipsis" disabled>
            <PaginationLink href="">...</PaginationLink>
          </PaginationItem>
        );
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <PaginationItem key={i} active={i === currentPage}>
            <PaginationLink href="" onClick={() => handleClick(i)}>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        );
      }

      if (endPage < totalPages - 1) {
        if (endPage < totalPages - 2) {
          pageNumbers.push(
            <PaginationItem key="end-ellipsis" disabled>
              <PaginationLink href="">...</PaginationLink>
            </PaginationItem>
          );
        }

        pageNumbers.push(
          <PaginationItem key={totalPages - 1}>
            <PaginationLink href="" onClick={() => handleClick(totalPages - 1)}>
              {totalPages}
            </PaginationLink>
          </PaginationItem>
        );
      }
    }

    return pageNumbers;
  };

  const toggle = () => setModal(!modal);

  const handleUpdateStatus = (e, id, status) => {
    e.preventDefault();
    dispatch(updateOrder(id, status));
  };
 const [refundloading,setRefundLoading]=useState(false);
  // handlePaymentRefund
  const handlePaymentRefund = (data) => {
    if (
      data.paymentMethod === "Stripe" ||
      data.paymentMethod === "Paypal" ||
      data?.paymentMethod === "cash"
    ) {
      setRefundLoading(true);
      dispatch(orderPaymentRefund(data,()=>{
        setTimeout(() => {
          dispatch(
            getAllOrders(
              search,
              user?.restaurantID,
              currentPage,
              filterValue,
              startWeek,
              endWeek,
              startDate,
              endDate,
              value[0],
              value[1]
            )
          );
        }, 2000);
        setRefundLoading(false);
      },toggle()));
    } else {
      toast.warning("Invalid payment method");
      toggle();
    }
  };

  useEffect(() => {
    dispatch(getRestaurantCurrency(user?.restaurantID));
  }, [user?.restaurantID]);

  return (
    <div className="orders pb-4 pt-5 pt-md-6">
      {/* <OnlyHeader /> */}
      <Container className="mt-5 pt-2" fluid>
        <Card className="p-3 orders__card-outer">
          <Row className="row my-2">
            <Col>
              <h2 className="mb-1">Orders</h2>
              <p className="text-muted">
                Manage your billing and payment details
              </p>
            </Col>
          </Row>
          <Row className="mx-2 d-lg-flex filters-row">
            {filters.map((filter, index) => {
              return (
                <div
                  key={index}
                  className={`px-3 py-1 ${
                    activeFilter === index ? "filter-active" : "filter"
                  }`}
                  onClick={() => {
                    setCurrentPage(0);
                    setActiveFilter(index);
                    setFilterValue(filter);
                  }}
                >
                  <p className="mb-0 text-capitalize">{filter}</p>
                </div>
              );
            })}
          </Row>
          <Row className="my-3 mx-2">
            <Col className="col-lg-4 col-md-4 col-sm-12">
              {" "}
              <FormGroup className="orders__form_group">
                <img src={searchImg} alt="search image" />
                <Input
                  type="text"
                  placeholder="Search by name, amount..."
                  value={search}
                  onChange={(e) => {
                    setCurrentPage(0);
                    setSearch(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col className="col-lg-4 col-md-4 col-sm-12 my-2 my-lg-0">
              <OrderRangeSlider
                value={value}
                setValue={setValue}
                setCurrentPage={setCurrentPage}
              />
            </Col>
            <Col className="col-lg-4 col-md-4 col-sm-12">
              <PickDateRange
                setDateState={setDateState}
                datestate={datestate}
              />
            </Col>
          </Row>
          <Row className="justify-content-around">
            <Col md={12} className="orders-table__col px-0">
              {user?.type === "kitchen-cook" || user?.type === "kitchen-admin" ? (
                <>
                  {orderPermissions?.get ? (
                    <>
                      {orderLoader ? (
                        <div
                          className="loader d-flex justify-content-center align-items-center"
                          style={{
                            overflow: "hidden",
                          }}
                        >
                          <Spinner size="sm" color="primary" />
                        </div>
                      ) : restaurantOrders.length>0?(
                        <Table
                          className="align-items-center table-flush mt-3 orders-table"
                          responsive
                        >
                           <thead className="thead-light">
                      <tr>
                        <th scope="col" className="text-center">Name</th>
                        <th scope="col"  className="text-center"
                              style={{
                                width: "150px",
                              }}>Order</th>
                        <th scope="col" className="text-center">Comments</th>
                        <th scope="col" className="text-center">Status</th>
                        <th scope="col" className="text-center">Payment</th>
                        <th scope="col" className="text-center">Amount</th>
                        <th scope="col" className="text-center">Actions</th>
                      </tr>
                    </thead>
                          <tbody>
                            {restaurantOrders?.map((item, idx) => {
                              return (
                                <tr key={idx}>
                                  {/* <td className="text-center">
                                    {item.customerID}
                                  </td> */}
                                  <td className="text-center">
                                    {item.customerName}
                                  </td>
                                  <td className="text-center">
                                    {item.order?.map((data) => {
                                      return (
                                        <>
                                          Dish - {data.name} Quantity -{" "}
                                          {data?.quantity} Price - {data?.price}
                                          &nbsp;
                                          {restaurantCurrency || "USD"}
                                          <br />
                                        </>
                                      );
                                    })}
                                  </td>
                                  <td className="text-center">
                                    {item.comments}
                                  </td>
                                  <td className="text-center">
                                    <span
                                      className={`status__${item.status} text-capitalize`}
                                    >
                                      {item.status}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    {item.paymentMethod}
                                  </td>
                                  <td className="text-center">
                                    {restaurantCurrency || "USD"}&nbsp;
                                    {item.totalAmount}
                                  </td>
                                  {orderPermissions?.update  && (
                                    <td className="d-flex justify-content-between align-items-center">
                                      
                                      {item.status === "ready" ? (
                                        <Button
                                          size="sm"
                                          className="mr-3 w-100 orders-table__paid"
                                          onClick={
                                            (e) =>
                                              handleUpdateStatus(
                                                e,
                                                item.id,
                                                "completed"
                                              )

                                            // dispatch(
                                            //   updateOrder(item.id, "completed")
                                            // )
                                          }
                                        >
                                          Move to completed
                                        </Button>
                                      ) : item.status === "pending" ? (
                                        <Button
                                          onClick={(e) =>
                                            handleUpdateStatus(
                                              e,
                                              item.id,
                                              "processing"
                                            )
                                          }
                                          size="sm"
                                          className="mr-3 w-100 orders-table__pending"
                                        >
                                          Move to Process
                                        </Button>
                                      ) : item.status === "processing" ? (
                                        <Button
                                          onClick={(e) =>
                                            // dispatch(updateOrder(item.id, "ready"))
                                            handleUpdateStatus(
                                              e,
                                              item.id,
                                              "ready"
                                            )
                                          }
                                          size="sm"
                                          className="mr-3 w-100 orders-table__process"
                                        >
                                          Move to Ready
                                        </Button>
                                      ) : item.status === "completed" ? (
                                        <Button
                                          // onClick={() =>
                                          //   dispatch(updateOrder(item.id, "ready"))
                                          // }
                                          size="sm"
                                          className="mr-3 w-100 orders-table__process"
                                        >
                                          Completed
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                      <PrintBill order={item}restaurantCurrency={restaurantCurrency} />
                                      <Button
                                        size="sm"
                                        className="mr-3  orders-table__process"
                                        disabled={item.status === "refunded"}
                                        onClick={() => {
                                          // handlePaymentRefund(item);
                                          setRefundItem(item);
                                          toggle();
                                        }}
                                      >
                                        Refund
                                      </Button>
                                      {/* <div>
                                        <img
                                          src={left}
                                          alt=""
                                          className="mr-2"
                                        />
                                        <img src={right} alt="" />
                                      </div> */}
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      ):(
                        <div className="d-flex flex-column justify-content-center align-items-center p-6 nodata">
                        <p className="text-danger"> No Orders Found</p>
                      </div>
                      )}
                    </>
                  ) : (
                    <Row className="py-4 justify-content-center align-items-center">
                      You don't have the permission to access the page
                    </Row>
                  )}
                </>
              ) : (
                <>
                  {orderLoader ? (
                    <div
                      className="loader d-flex justify-content-center align-items-center"
                      style={{ overflow: "hidden" }}
                    >
                      <Spinner size="sm" color="primary" />
                    </div>
                  ) : restaurantOrders?.length>0? (
                    <Table
                      className="align-items-center table-flush mt-3 orders-table"
                      responsive
                    >
                       <thead className="thead-light">
                       <tr>
                        <th scope="col" className="text-center">Name</th>
                        <th scope="col"  className="text-center"
                              style={{
                                width: "150px",
                              }}>Order</th>
                        <th scope="col" className="text-center">Comments</th>
                        <th scope="col" className="text-center">Status</th>
                        <th scope="col" className="text-center">Payment</th>
                        <th scope="col" className="text-center">Amount</th>
                        <th scope="col" className="text-center">Actions</th>
                      </tr>
                       </thead>
                      <tbody>
                        {restaurantOrders?.map((item,index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">
                                {item?.customerName}
                              </td>
                              <td className="text-center">
                                {item.order?.map((data,ind) => {
                                  return (
                                    <React.Fragment key={ind}>
                                      Dish - {data.name} Quantity -{" "}
                                      {data?.quantity} Price - {data?.price}
                                      &nbsp;
                                      {restaurantCurrency || "USD"}
                                      <br />
                                    </React.Fragment>
                                  );
                                })}
                              </td>
                              <td className="text-center">{item.comments}</td>
                              <td className="text-center">
                                <span
                                  className={`status__${item.status} text-capitalize`}
                                >
                                  {item.status}
                                </span>
                              </td>
                              <td className="text-center">
                                {item.paymentMethod}
                              </td>
                              <td className="text-center">
                                {restaurantCurrency || "USD"}&nbsp;
                                {item.totalAmount}
                              </td>

                              <td className="d-flex justify-content-between align-items-center">
                                {item.status === "ready" ? (
                                  <Button
                                    size="sm"
                                    className="mr-3 w-100 orders-table__paid"
                                    onClick={
                                      (e) =>
                                        handleUpdateStatus(
                                          e,
                                          item.id,
                                          "completed"
                                        )
                                    }
                                  >
                                    Move to completed
                                  </Button>
                                ) : item.status === "pending" ? (
                                  <Button
                                    onClick={(e) =>
                                      handleUpdateStatus(
                                        e,
                                        item.id,
                                        "processing"
                                      )
                                    }
                                    size="sm"
                                    className="mr-3 w-100 orders-table__pending"
                                  >
                                    Move to Process
                                  </Button>
                                ) : item.status === "processing" ? (
                                  <Button
                                    onClick={(e) =>
                                      // dispatch(updateOrder(item.id, "ready"))
                                      handleUpdateStatus(e, item.id, "ready")
                                    }
                                    size="sm"
                                    className="mr-3 w-100 orders-table__process"
                                  >
                                    Move to Ready
                                  </Button>
                                ) : item.status === "completed" ? (
                                  <Button
                                    // onClick={() =>
                                    //   dispatch(updateOrder(item.id, "ready"))
                                    // }
                                    size="sm"
                                    className="mr-3 w-100 orders-table__process"
                                  >
                                    Completed
                                  </Button>
                                ) : (
                                  ""
                                )}
                                <PrintBill order={item} restaurantCurrency={restaurantCurrency} />
                                <Button
                                  size="sm"
                                  className="mr-3  orders-table__process"
                                  disabled={item.status === "refunded"}
                                  onClick={() => {
                                    // handlePaymentRefund(item);
                                    setRefundItem(item);
                                    toggle();
                                  }}
                                >
                                  Refund
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ):(
                    <div className="d-flex flex-column justify-content-center align-items-center p-6 nodata">
                    <p className="text-muted"> No Orders Found</p>
                  </div>
                  )}
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem disabled={currentPage === 0}>
                          <PaginationLink
                            href=""
                            onClick={() => handleClick(currentPage - 1)}
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        {/* Page numbers */}
                        {renderPageNumbers()}
                        {/* Next button */}
                        <PaginationItem
                          disabled={
                            currentPage ===
                            restaurantOrdersRestData?.nbPages - 1
                          }
                        >
                          <PaginationLink
                            href=""
                            onClick={() => handleClick(currentPage + 1)}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter>
                </>
              )}
            </Col>
          </Row>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Refund Order</ModalHeader>
            <ModalBody>Are you sure you want to refund the order?</ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                color="danger"
                disabled={refundloading}
                onClick={() => handlePaymentRefund(refundItem)}
              >
               {refundloading? <Spinner/>:"Confirm"} 
              </Button>{" "}
              <Button size="sm" color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Card>
      </Container>
    </div>
  );
};

export default OrdersResturant;
