/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { Container } from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import RequestTable from "components/Requests/RequestsTable";
import { useDispatch, useSelector } from "react-redux";

const Requests = () => {
  const { restaurantRequests } = useSelector((state) => state.requests);
  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <RequestTable data={restaurantRequests} />
      </Container>
    </>
  );
};

export default Requests;
