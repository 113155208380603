
import React from "react";
import img from '../../assets/img/restaurant.jpg';
import { useHistory } from "react-router-dom";

function AboutUs() {
  const history=useHistory();
  return (
    <>
      {/* <LandingHeader/> */}
      <section className="about-section mt-7">
        <div className="container">
          <div className="row">
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column wow fadeInLeft">
                <figure className="image-1">
                  <a href="/" className="lightbox-image" data-fancybox="images">
                    <img src={img} alt="restaurant" />
                  </a>
                </figure>
              </div>
            </div>
            <div className="content-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  <span className="title text-muted">About Us</span>
                  <h2>Welcome to NextEats</h2>
                </div>
                <div className="text">
                  Since our founding in 2019, NextEats has been dedicated to revolutionizing the dining experience through innovative technology. Our mission is to empower restaurants with the tools they need to streamline their operations, enhance customer satisfaction, and achieve new heights of efficiency and success.
                </div>
                <div className="sec-title">
                  <h2>Our Story</h2>
                </div>
                <div className="text">
                  NextEats was born out of a passion for great food and seamless dining experiences. Recognizing the challenges that restaurants face in managing reservations, orders, customers, and menus, we set out to create a comprehensive solution that would address these needs with simplicity and elegance.
                </div>
                <div onClick={()=>history.push('/contact')} className="btn-box">
                  <a href="/" className="theme-btn btn-style-one">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="sec-title">
                <h2>What We Do</h2>
              </div>
              <div className="text">
                At NextEats, we provide a robust Software-as-a-Service (SaaS) platform designed to handle all aspects of restaurant management:
                <ul>
                  <li><strong>Table Management:</strong> Optimize seating arrangements and manage reservations with ease, ensuring your guests have a smooth and enjoyable dining experience.</li>
                  <li><strong>Order Management:</strong> Streamline order processing from kitchen to table, reducing errors and improving service speed.</li>
                  <li><strong>Customer Management:</strong> Keep track of customer preferences, special occasions, and feedback to personalize service and build lasting relationships.</li>
                  <li><strong>Menu Management:</strong> Easily update and manage your menu, including pricing, availability, and special offers, all in real-time.</li>
                </ul>
              </div>
              <div className="sec-title">
                <h2>Our Values</h2>
              </div>
              <div className="text">
                <ul>
                  <li><strong>Innovation: {" "}</strong>  We are constantly evolving our platform to incorporate the latest technological advancements, ensuring that our clients stay ahead in the competitive restaurant industry.</li>
                  <li><strong>Customer-Centricity: {" "} </strong> Our clients' success is our success. We are committed to providing exceptional support and continuously improving our services based on client feedback.</li>
                  <li><strong>Integrity:{" "}</strong>  We operate with transparency and honesty, building trust with our clients through reliable and ethical business practices.</li>
                </ul>
              </div>
              <div className="sec-title">
                <h2>Our Team</h2>
              </div>
              <div className="text">
                The NextEats team is a group of dedicated professionals with a shared passion for food and technology. Our diverse backgrounds in software development, hospitality, and customer service equip us with the unique insights and skills needed to create a top-tier restaurant management platform.
              </div>
              <div className="sec-title">
                <h2>Looking Ahead</h2>
              </div>
              <div className="text">
                As we continue to grow, we remain focused on our goal: to make restaurant management effortless and enjoyable. We are excited about the future and the new features and improvements we plan to bring to our platform, all aimed at enhancing the dining experience for both restaurants and their patrons.
              </div>
              <div className="sec-title">
                <h2>Join Us</h2>
              </div>
              <div className="text">
                Whether you're a small café or a large dining establishment, NextEats is here to support your journey. Join the many satisfied clients who have transformed their operations with our platform and discover how NextEats can help you achieve your goals.
                <br/><br/>
                Thank you for being part of the NextEats community. Together, let's create unforgettable dining experiences.
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;
