import axios from "axios";
const baseDomain = "https://us-central1-menux1.cloudfunctions.net/app";
// 'https://us-central1-pinea-restaurant.cloudfunctions.net/app';

const baseURL = `${baseDomain}`;

export default axios.create({
  baseURL,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
  },
});
