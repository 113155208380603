import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { updateLanguages } from "store/actions/settingAction";

const RestaurantLanguage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.setting);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState("");

  const options = [
    { value: "en", label: "English" },
    { value: "es", label: "Spanish" },
    { value: "fr", label: "French" },
    { value: "ur", label: "Urdu" },
    { value: "de", label: "German" },
  ];

  const handleSelect = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedLanguages(selectedValues);
  };

  useEffect(() => {
    if (user?.languages) {
      setSelectedLanguages(user?.languages);
    } else {
      setSelectedLanguages([]);
    }

    if (user?.language) {
      setSelectedDefaultLanguage(user?.language);
    } else {
      setSelectedDefaultLanguage("");
    }
  }, [user]);

  const handleLanguageSubmit = (evt) => {
    evt.preventDefault();
    dispatch(updateLanguages(uid, selectedLanguages));
  };

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7 mb-5" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="d-lg-flex  d-sm-block justify-content-between">
                <div className="d-flex align-items-center">
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => {
                      history.push("/admin/venue-setting-menu");
                    }}
                  >
                    <i className="fas fa-arrow-left "></i>
                  </Button>

                  <h3 className=" pt-2 ">Restaurant Language</h3>
                </div>
              </CardHeader>
              <div className="mx-3 mx-md-5 my-4">
                <Form onSubmit={handleLanguageSubmit}>
                  <FormGroup>
                    <Label for="languages">Default language:</Label>
                    <Input
                      placeholder="Select Languages"
                      value={
                        options.find(
                          (option) => option.value === selectedDefaultLanguage
                        )?.label
                      }
                      disabled
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="languages">Select languages:</Label>
                    <Select
                      placeholder="Select Languages"
                      isMulti
                      options={options} // No filtering, all options will be available
                      value={options.filter((option) =>
                        selectedLanguages.includes(option.value)
                      )}
                      // options={options.filter(
                      //   (option) => option.value !== selectedDefaultLanguage
                      // )}
                      // value={options.filter((option) =>
                      //   selectedLanguages.includes(option.value)
                      // )}
                      onChange={handleSelect}
                    />
                  </FormGroup>

                  <div className="d-flex justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={selectedLanguages.length === 0 || loading}
                    >
                      {loading ? <Spinner size="sm" /> : "Save"}
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default RestaurantLanguage;
